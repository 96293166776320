import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import Hobbies from "../profile/Hobbies";
import { validFile } from "../../libs/functions";
import ProfileStats from "./ProfileStats";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import MultiSelect from "./MultiSelect";
import CustomModal from "../shared/CustomModal.js";
import { FaExpand, FaEye } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { _backgroundImagePrompt } from "../../libs/constants.js";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "#profile",
      reviews: [],
      genres: [],
      selectedGenres: [],
      cover: "",
      profile_photo: "",
      tabs: [
        {
          url: "edit",
          icon: "fa-user",
          name: "Personal Info",
        },
        {
          url: "workhistory",
          icon: "fa-video",
          name: "Work History",
        },
        {
          url: "info",
          icon: "fa-star",
          name: "Addtional Info",
        },
      ],
      isMobile: false,
    };

    if (this.props.tabs) {
      this.state.tabs = this.props.tabs;
    }

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.uploaderMode = "cover";
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };


  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    if (!this.state.id) {
      return <div />;
    }

    var occupation =
      this.state.occupation && this.state.occupation.length > 0 ? (
        this.state.occupation[0].occupation.title
      ) : (
        <p>&nbsp;</p>
      )

    var occupation_counts =
      this.state.occupation && this.state.occupation.length > 0 ? (
        this.state.occupation.length - 1
      ) : (0);

    var _errors = <div />;
    if (this.state.errors) {
      _errors = (
        <div className="alert alert-danger">{this.state.errors.join(", ")}</div>
      );
    }


    const tabsToRender = this.state.isMobile
    ? this.state.tabs.filter(tab => tab.name !== "Work History")
    : this.state.tabs;


    var _tabs = [];
    tabsToRender.map((t, idx) => {
      var _active = "edit" === t.url ? "active" : "";
      var _url = this.props.url ? this.props.url : "/profile/0/" + t.url;
      _tabs.push(
        <li key={idx} className={_active}>
          <a
            className={_active}
            onClick={() => {
              this.setState({ tab: t.url });
            }}
            href={_url}
          >
            <i className={"fas " + t.icon} />
            <br />
            {t.name}
          </a>
        </li>
      );
    });
    var _miles = this.state.work_radius ? this.state.work_radius : 1;
    return (
      <React.Fragment>
        <div className="" style={{float: "left" }}>
          <div
            className="col-9"
            style={{
              // background:"purple", 
              marginTop: "108px",
              marginLeft: "",
              maxWidth: "unset",
            }}
          >
            <div className="profile-form">
              <div className="title"></div>

              
              <div className="upload" style={{ height: "275px", position: "relative" }}>
                <div className="image-upload" onClick={() => this.handleFileClick("cover")}>
                  <div id="backgroundPrompt" style={_backgroundImagePrompt}>
                    Upload background image
                  </div>
                  <input
                    type="file"
                    className="hidden-upload-field"
                    id="cover-image-field"
                    onChange={this.handleFileUpload}
                  />
                </div>
             
                {this.state.cover && (
                  <>
                
                  <MdEdit
                  onClick={() => this.handleFileClick("cover")}
                  style={{
                    fontSize: "18px",
                    color: "white",
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "68px",
                    right: "-14px", // Adjusted position for Edit icon
                    width: "90px",
                  }}
                />
                </>
                )}

                <div className="edit-profile-pic-name" style={{display:"flex",alignItems:"center",justifyContent:""}}>
                <div
                    className="camera-btn"
                    onClick={() => this.handleFileClick("profile_photo")}
                  >
                    <a href="#" id="avatar"></a>
                  {this.state.profile_photo && (
                    <MdEdit
                    onClick={() => this.handleFileClick("profile_photo")}
                    style={{
                      fontSize: "18px",
                      color: "white",
                      cursor: "pointer",
                      position: "absolute",
                      bottom: "0px",
                      right:"-30px",
                      // left: "88px", // Adjusted position for Edit icon
                      width: "80px",
                    }}
                  />
                  )}



             

                  </div>

                  <div
                  className="profile-id"
                  style={{
                    float: "unset",
                    // marginTop: "-103px",
                    // marginLeft: "152px",
                  }}
                >
                  <b>
                    {this.state.first_name} {this.state.last_name}
                  </b>
                  <br />
                  <span style={{ fontSize: "16px" }}>{occupation} <span className={occupation_counts > 0 ? '' : 'hidden'}> & {occupation_counts} more</span></span>
                </div>
                  
                </div>

              

                <div className="stats" style={{ float: "left" }}></div>
                <div
                  className="prompt"
                  style={{
                    marginRight: "0px",
                    textAlign: "right",
                    width: "255px",
                    marginTop: "9px",
                  }}
                >
                  <ProfileStats user={this.state} showRequestButton={false} />
                </div>
                <input
                  type="file"
                  className="hidden-upload-field"
                  id="profile_photo-image-field"
                  onChange={this.handleFileUpload}
                />
              </div>
              <div className="col-12 profile-tabs" style={{ float: "left" }}>
                <ul className="mobile_tabs">{_tabs}</ul>
              </div>
              <div
                className="col-12 pl-0 pr-0 pt-16 profile-fields"
                style={{ marginTop: "18px", float: "left" }}
              >
                {_errors}
                <form id="profileForm" className="gorilla-form">
                  <h2>Personal Information</h2>

                  <div className="row m-0 p-0">
                    <div className="col-12 col-sm-6 m-0 p-0 pr-sm-4 pl-6">
                      <label>First Name</label>
                      <input
                        name="first_name"
                        value={this.state.first_name || ""}
                        onChange={this.handleFormChange}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-12 col-sm-6 m-0 pl-0 pl-sm-6 pr-0 pb-4">
                      <label>Last Name</label>
                      <input
                        name="last_name"
                        value={this.state.last_name || ""}
                        onChange={this.handleFormChange}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-12 col-sm-6 m-0 p-0  pr-sm-4 pl-6">
                      <label>Phone</label>
                      <PhoneInput
                        value={this.state.phone || ""}
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </div>
                    <div className="col-12 col-sm-6 m-0 pl-0 pl-sm-6 pr-0 pb-4">
                      <label>Zip Code</label>
                      <input
                        name="zip"
                        value={this.state.zip == "null" ? "" : this.state.zip || ""}
                        onChange={this.handleFormChange}
                        className="form-control"
                        placeholder="Zip"
                      />
                    </div>
                    <div className="col-12 col-sm-6 m-0 p-0  pr-sm-4">
                      <label>IMDb Link</label>
                      <input
                        name="imdb"
                        value={this.state.imdb || ""}
                        onChange={this.handleFormChange}
                        className="form-control"
                        placeholder="IMDb Link"
                      />
                    </div>
                    <div className="col-12 col-sm-6 m-0 pl-0 pl-sm-6 pr-0 pb-4">
                      <label>Occupations</label>
                      <ReactSelect
                        placeholder="Select Occupations"
                        options={this.state.genres}
                        isMulti
                        value={this.state.selectedGenres}
                        onChange={(selectedOptions) => this.setState({ selectedGenres: selectedOptions })}
                      />
                    </div>
                    <div className="col-12 m-0 p-0 pb-4">
                      <label>Email</label>
                      <input
                        name="email"
                        value={this.state.email || ""}
                        onChange={this.handleFormChange}
                        className="form-control"
                        placeholder="Email"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-12 m-0 p-0 pb-4">
                      <label>Work Radius</label>
                      <br />
                      <input
                        type="range"
                        style={{ width: "100%" }}
                        id="work_radius"
                        defaultValue={this.state.work_radius || "1"}
                        onChange={this.handleSlider.bind(this)}
                        min="1"
                        max="12500"
                        step="1"
                      />
                      <br />
                      <span className="sliderVal">{_miles}</span> miles
                    </div>
                    <div className="col-12 m-0 p-0 pb-4">
                      <label>About Me</label>
                      <textarea
                        name="tell_us"
                        value={this.state.tell_us || ""}
                        onChange={this.handleFormChange}
                        style={{ height: "160px" }}
                        className="form-control textarea"
                        placeholder="Tell us about yourself"
                      ></textarea>
                    </div>
                    <Hobbies
                      user_hobbies={this.state.hobbies || []}
                      onHobbiesChange={(hobbies) => {
                        this.setState({ hobbies: hobbies });
                      }}
                    />

                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0">
                        <button
                          className="g-button"
                          type="button"
                          onClick={this.handleProfileSave.bind(this)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleReviews() { }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSlider(e) {
    let slider = document.querySelector(".sliderVal");
    slider.innerText = e.target.value;
  }
  handleOccupations() {
    let apiRequest = new ApiRequest("post", "/user/update-profile");
    let params = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      email: this.state.email,
      occupation: this.state.selectedGenres.map((genre) => genre.value),
      zip: this.state.zip,
      imdb: this.state.imdb,
      work_radius: document.getElementById("work_radius").value,
      tell_us: this.state.tell_us,
    };
    apiRequest.send(params, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        location.href = "/profile/occupations";
      }
    });
  }

  handleFileUpload() {
    var formData = new FormData();
    var imagefile = document.querySelector(
      "#" + this.uploaderMode + "-image-field"
    );
    formData.append(this.uploaderMode, imagefile.files[0]);
    if (!validFile(imagefile, "photo")) {
      return;
    }
    let apiRequest = new ApiRequest("post", "/user/update-profile");
    apiRequest.send_file(formData, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        if (this.uploaderMode === "cover") {
          document.querySelector("#backgroundPrompt").style.visibility =
            "hidden";
          document.querySelector(".image-upload").style.backgroundImage =
            "url('" + result.data.user.cover + "')";
        } else {
          document.querySelector("#avatar").style.visibility = "hidden";
          document.querySelector(".camera-btn").style.backgroundImage =
            "url('" + result.data.user.profile_photo + "')";
        }
      }
    });
    this.fetchProfile();
  }

  phonenumber(val) {
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return val.match(phoneno);
  }

  isValidUrl(str) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  handleProfileSave() {
    let apiRequest = new ApiRequest("post", "/user/update-profile");
    let params = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      zip: this.state.zip,
      imdb: this.state.imdb,
      work_radius: document.getElementById("work_radius").value,
      tell_us: this.state.tell_us,
      occupation_ids: this.state.selectedGenres.map((genre) => genre.value),
      occupation: this.state.selectedGenres.map((genre) => genre.value),
    };

    var _errors = [];
    if (
      !params["first_name"] ||
      (params["first_name"] && params["first_name"].length < 1)
    ) {
      _errors.push("First name is required");
    }
    if (
      params["phone"] == null ||
      (params["phone"] && params["phone"].length < 1)
    ) {
      _errors.push("Phone is required");
    }

    if (params["imdb"] && !this.isValidUrl(params["imdb"])) {
      _errors.push("IMDB Link is not a valid url");
    }
    if (params["zip"] && isNaN(this.state.zip)) {
      _errors.push("Zipcode is not valid");
    }

    if (this.state.hobbies.length < 3) {
      toast.error("Please select at least 3 hobbies");
      return;
    }

    if (_errors.length > 0) {
      this.setState({ errors: _errors });
      window.scrollTo(0, 0);
      return;
    } else {
      this.setState({ errors: null });
    }
    apiRequest.send(params, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
        window.scrollTo(0, 0);
      } else {
        //("/projects/new");
        window.scrollTo(0, 0);
      }
    });
  }

  handleFileClick(uploader) {
    document.getElementById(uploader + "-image-field").click();
    this.uploaderMode = uploader;
  }

  fetchProfile() {
    let api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      let result = response.data;
      var user = result.user;
      this.setState(user);
      if (user.cover) {
        document.querySelector("#backgroundPrompt").style.display = "none";
        document.querySelector(".image-upload").style.backgroundImage =
          "url('" + user.cover + "')";
        this.setState({ cover: user.cover });
      }
      if (user.profile_photo) {
        document.querySelector("#avatar").style.display = "none";
        document.querySelector(".camera-btn").style.backgroundImage =
          "url('" + user.profile_photo + "')";
        this.setState({ profile_photo: user.profile_photo });
      }
      if (user.occupation && user.occupation.length > 0) {
        this.setState({ selectedGenres: user.occupation.map((genre) => ({ value: genre.occupation.id, label: genre.occupation.title })) });
      }

      this.setState(user);
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Set initial state for isMobile
    
    this.fetchProfile();
    let interests = new ApiRequest("get", "/user/interests");
    interests.send({}, (response) => {
      this.setState({ hobbies: response.data.interests });
    });


    let genreApi = new ApiRequest("get", "/user/crew-types");
    genreApi.send({}, (response) => {
      const options = response.data.types.map((primaryType) => {
        const primaryOption = {
          label: primaryType.name,
          options: primaryType.secondary.map((secondaryType) => ({
            value: secondaryType.id,
            label: secondaryType.name,
          })),
        };
        return primaryOption;
      });

      this.setState({ genres: options });
    });

  }


}

export default EditProfile;
