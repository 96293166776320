import React, { Component } from "react";
import Banner from "./../profile/Banner";
import ProfileForm from "../profile/ProfileForm";

class OnProfile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid header">
        <div className="logo-wrapper">

<div className="logo"></div>
</div>
          <div className="row justify-content-center">
            <div
              className="col-9"
              style={{
                marginTop: "180px",
                marginLeft: "30px",
                maxWidth: "unset",
              }}
            >
              <div className="profile-form">
                <div className="title">
                  <h2>Please enter your profile details</h2>
                </div>
                <Banner user={this.props.user} />
                <div
                  className="col-12 pl-0 pr-0 pt-16 profile-fields"
                  style={{ marginTop: "18px", float: "left" }}
                >
                  <ProfileForm
                    user={this.props.user}
                    next={"/onboarding/projects"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default OnProfile;
