import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset } from "../../libs/functions";
import PostQuestionList from "./PostQuestionList";
import ReactSelect from "react-select";

class EditMusic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {}, questions: [], post_type: 9,
      instrumentation: [],
      selectedInstrumentation: [],
      production_genre: [],
      selectedProductionGenre: "",
      mood_emotion: [],
      selectedMoodEmotion: "",
      musical_style: [],
      selectedMusicalStyle: "",
      title: "",
      duration: 1,
      tempo: [],
      selectedTempo: "",
      description: "",
      link: ""
    };
    this.loadProject();
  }

  render() {
    if (!this.state.questions) {
      return <div />;
    }

    return (
      <React.Fragment>
        <div className="container-fluid header">
        <div className="logo-wrapper">

          <div className="logo"></div>
        </div>
          <a
            href="/profile/0/workhistory"
            style={{ marginLeft: "-696px", color: "white" }}
          >
            &lt; Back
          </a>
        </div>
        <div className="row justify-content-center projects"></div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Music</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="2"
            id="post_type_id"
            name="post[post_type]"
          />

          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <label>Music</label>
              <input
                type="text"
                id="title"
                name="title"
                defaultValue={this.state.title || ""}
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder="Name of your project/film…"
                className="form-control"
              />
            </div>

            {/* <PostQuestionList
              post={this.state.post}
              questions={this.state.music_meta}
              fields={[
                "Instrumentation",
                "Production Genre",
                "Mood / Emotion",
                "Musical Style",
                "Select Duration",
                "Tempo",
              ]}
            /> */}

            <div className="form-group">
              <label>Instrumentation</label>
              <ReactSelect
                placeholder="Select Instrumentation"
                isMulti
                onChange={(selectedInstrumentation) => {
                  this.setState({ selectedInstrumentation });
                }
                }
                options={
                  this.state.instrumentation
                } />
            </div>

            <div className="form-group">
              <label>Production Genre</label>
              <ReactSelect
                placeholder="Select Production Genre"
                defaultValue={this.state.selectedProductionGenre}
                onChange={(selectedProductionGenre) => {
                  this.setState({ selectedProductionGenre });
                }
                }
                options={
                  this.state.production_genre
                } />
            </div>

            <div className="form-group">
              <label>Mood / Emotion</label>
              <ReactSelect
                placeholder="Select Mood / Emotion"
                defaultValue={this.state.selectedMoodEmotion}
                onChange={(selectedMoodEmotion) => {
                  this.setState({ selectedMoodEmotion });
                }
                }
                options={
                  this.state.mood_emotion
                } />
            </div>

            <div className="form-group">
              <label>Musical Style</label>
              <ReactSelect
                placeholder="Select Musical Style"
                defaultValue={this.state.selectedMusicalStyle}
                onChange={(selectedMusicalStyle) => {
                  this.setState({ selectedMusicalStyle });
                }
                }
                options={
                  this.state.musical_style
                } />
            </div>

            <div className="form-group">
              <label>Select Duration</label>
              <input
                type="range"
                min="1"
                max="10"
                step={0.5}
                id="duration"
                name="duration"
                defaultValue={this.state.duration || ""}
                onChange={() => {
                  this.setState({ duration: document.getElementById("duration").value });

                }}
                placeholder="Duration"
                className="form-control"
              />
              <div>
                {this.state.duration} minutes
              </div>


            </div>

            <div className="form-group">
              <label>Tempo</label>
              <ReactSelect
                placeholder="Select Tempo"
                defaultValue={this.state.selectedTempo}
                onChange={(selectedTempo) => {
                  this.setState({ selectedTempo });
                }
                }
                options={
                  this.state.tempo
                } />
            </div>


            <div className="form-group">
              <label>Link</label>
              <input
                type="text"
                id="link"
                name="link"
                defaultValue={this.state.link || ""}
                onChange={(e) => this.setState({ link: e.target.value })}
                placeholder="Link "
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                id="description"
                name="description"
                defaultValue={this.state.description || ""}
                onChange={(e) => this.setState({ description: e.target.value })}
                placeholder="Description"
                className="form-control"
              ></textarea>

            </div>


          </div>
          <div className="col-5 m-0 p-0 mr-5" style={{ float: "left" }}>
            <button
              className="g-button cancel full"
              onClick={() => (window.location.href = "/profile/0/workhistory")}
            >
              Cancel
            </button>
          </div>
          <div className="col-6 ml-3 p-0" style={{ float: "left" }}>
            <button
              className="g-button full"
              onClick={this.handleSubmit.bind(this)}
            >
              Save
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmit() {
    const id = this.props.match.params.id;
    var params = {
      name: this.state.title,
      description: this.state.description,
      link: this.state.link,
      instrumentations: this.state.selectedInstrumentation.map((item) => item.value),
      production: this.state.selectedProductionGenre.value,
      emotion: this.state.selectedMoodEmotion.value,
      style: this.state.selectedMusicalStyle.value,
      duration: this.state.duration,
      tempo: this.state.selectedTempo.value
    };
    var api = new ApiRequest("post", "/user/add-music");
    if (id) {
      api.method = "post";
      api.url = "/user/edit-music";
      params.music_id = id;
    }
    api.send(params, (result) => {
      window.location.href = "/profile/0/workhistory";
    });
  }

  loadProject() {


    
    let api = new ApiRequest("get", "/user/get-music-meta");
    api.send({}, (response) => {
      // match title of Instrumentation from response.data and set to the Instrumentation
      var instrumentation = response.data.find((item) => item.title === "Instrumentation");
      var convertedInstrumentation = instrumentation.childs.map((genre) => {
        return { value: genre.id, label: genre.title }
      });;

      // match title of Production Genre from response.data and set to the Production Genre

      var production_genre = response.data.find((item) => item.title === "Production Genre");
      var convertedProductionGenre = production_genre.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // match title of Mood / Emotion from response.data and set to the Mood / Emotion
      var mood_emotion = response.data.find((item) => item.title === "Mood Emotion");
      var convertedMoodEmotion = mood_emotion.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // match title of Musical Style from response.data and set to the Musical Style
      var musical_style = response.data.find((item) => item.title === "Music Style");
      var convertedMusicalStyle = musical_style.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // temp
      var tempo = response.data.find((item) => item.title === "Tempos");
      var convertedTempo = tempo.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      this.setState({
        instrumentation: convertedInstrumentation,
        production_genre: convertedProductionGenre,
        mood_emotion: convertedMoodEmotion,
        musical_style: convertedMusicalStyle,
        tempo: convertedTempo
      });
    });

    const id = this.props.match.params.id;
    if (isset(id)) {
      let api = new ApiRequest("get", "/user/music-detail?music_id=" + id);
      api.send({}, (response) => {
        var _music = response.data.music;
        
        this.setState({
          title: _music.name,
          description: _music.description,
          link: _music.link,
          selectedInstrumentation: _music.user_music_meta.map((item) => {
            return { value: item.id, label: item.title }
          }),
          selectedProductionGenre: { value: _music.production, label: _music.production },
          selectedMoodEmotion: { value: _music.emotion, label: _music.emotion },
          selectedMusicalStyle: { value: _music.style, label: _music.style },
          duration: _music.duration,
          selectedTempo: { value: _music.tempo, label: _music.tempo }
        });

      });
    } 
    

  }

  handleFormChange(e) {
    // this.setState({ [e.target.name]: e.target.value });
  }
}

export default EditMusic;
