import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid signup">
          <div className="row">

            {/* ------------- Show text on desktop ------------ */}

              <div className="col-md-6 left-panel show-text-on-desktop">
                <p className="auth-intro-text-home">
                Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
                </p>
              </div>

            <div className="col-md-6 right-panel form-panel-modi">
              <div className="panel" style={{ margin: "auto" }}>
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/logo.png"
                    className="homelogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Create Art, Share Skills!</span>
                </div>
                <div className="buttons">
                  <a href="/login" className="btn btn-light ">
                    Login
                  </a>
                  <a href="/signup" className="btn btn-light btnpad ">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>


            {/* ---------- Show text on Mobile --------------- */}

              <div className="col-md-6 left-panel show-text-on-mobile">
                <p className="auth-intro-text-home">
                Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
                </p>
              </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
