import React, { Component } from "react";
import { FcAcceptDatabase, FcAddImage, FcAdvertising, FcBusinessman, FcConferenceCall, FcList, FcPortraitMode, FcReadingEbook } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CurrentUser from "../../libs/CurrentUser";
// import './FeedNav.css'; // Import CSS file for custom styling
import { TiThMenu } from "react-icons/ti";
import Popup from "reactjs-popup";
import SettingsPanel from "./SettingsPanel";
import MessagesPanel from "./MessagesPanel";
import Alerts from "./Alerts";
import ApiRequest from "../../libs/request";
class FeedNav extends Component {
  
  state = {
    isOpen: false,
    alerts: 0, search_results: {}
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    var _alerts_count = [];
    const { isOpen } = this.state;
    const _avatar_image = this.props.user && this.props.user.profile_photo 
    ? this.props.user.profile_photo 
    : "/images/short-logo.png";
    return (
      <React.Fragment>
        {/* Toggle Button */}
        {/* <button className="sidebar-toggle-btn" onClick={this.toggleSidebar}>
          {isOpen ? "Close" : "Open"} Menu
        </button> */}
        <div  className="sidebar-toggle-btn" onClick={this.toggleSidebar}>

        <TiThMenu />
        </div>

        {/* Sidebar */}
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div style={{ marginTop: "12px", marginLeft: "15px" }}>

          <div className="nav-right-content small-screen-nav-end" style={{marginBottom:"20px", display:"flex",justifyContent:"center",marginTop:"20px", gap:"5px"}}>
                    <div className="alerts-control">
                      <Popup
                        trigger={(open) => (
                          <div className="header-messages" style={{ right: '7px' }} title="Notifications">
                            <div >
                              {_alerts_count}
                            </div>
                            <i className="fas fa-bell header-bell-icon"></i>
                          </div>
                        )}
                        position="bottom center"
                        closeOnDocumentClick
                      >
                        <Alerts
                          user={this.props.user}
                          onAlertsChange={this.getAlertCount.bind(this)}
                        />
                      </Popup>
                    </div>

                    {/* <a href="/messages" title="New Message">
                      <div className="header-messages">
                        <i className="fas fa-comment-dots header-msg-icon"></i>
                      </div>
                    </a> */}
                    <div className="messages-control">
                      <Popup
                        trigger={(open) => (
                          <div className="header-messages" style={{right: '7px', cursor: 'pointer' }} title="Messages">
                            <div className="header-messages">
                              <i className="fas fa-comment-dots header-msg-icon"></i>
                            </div>
                          </div>
                        )}
                        position="bottom center"
                        closeOnDocumentClick
                      >
                        <div style={{
                          float: "left",
                          textAlign: "left"
                        }} >

                          <MessagesPanel user={this.props.user} nav={true} />
                        </div>

                      </Popup>
                    </div>
                    <div className="settings-pop">
                      <Popup
                        trigger={(open) => (
                          <div className="user-avatar">
                            <img src={"/images/short-logo.jpg"} className="header-avatar" style={{width:"30px",marginLeft:"15px",marginRight:"5px"}} />
                            <i className="fas fa-angle-down avatar-angleDownIcon"></i>
                          </div>
                        )}
                        position="bottom right"
                        closeOnDocumentClick
                      >
                        <SettingsPanel user={this.props.user} userImage={_avatar_image} userName={this.props.user.first_name ? `${this.props.user.first_name} ${this.props.user.last_name}` : "Username"} />
                      </Popup>
                    </div>
                  </div>


            <a href="/" >
              <FcAdvertising className="news-feed-left-icon" />
              <span>News Feed</span>
            </a>
            <a href="/profile/0/edit" >
              <FcPortraitMode className="news-feed-left-icon" />
              <span>Edit Profile</span>
            </a>
            <a href="/friends" >
              <FcConferenceCall className="news-feed-left-icon" />
              <span>Friends</span>
            </a>
            <a href="/review-requests" >
              <FcReadingEbook className="news-feed-left-icon" />
              <span>Review Requests</span>
            </a>
            <a href="/companies" >
              <FcBusinessman className="news-feed-left-icon" />
              <span>Company Profiles</span>
            </a>
            <a href={`https://admin.todayspainter.com/movie/add?token=${CurrentUser.token()}`} target="_blank" rel="noopener noreferrer">
              <FcAddImage className="news-feed-left-icon" />
              <span>Add Video</span>
            </a>
            <a href="/my-list" >
              <FcList className="news-feed-left-icon" />
              <span>My Videos</span>
            </a>



       




          </div>
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }

  
  componentDidMount() {
    this.getAlertCount();
  }

  getAlertCount() {
    if (Object.keys(this.props.user).length === 0) {
      return;
    }
    var api = new ApiRequest("get", "/user/get-notifictions");
    api.send({}, (response) => {
      this.setState({
        alerts:
          response.data.notifications ? response.data.notifications.filter((a) => a.status == "unread").length : 0
      });
    });
  }

}

export default FeedNav;
