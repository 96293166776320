import React, { Component } from "react";
import ApiRequest from "../libs/request.js";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.password = React.createRef();
    this.confirmPassword = React.createRef();
    this.vCode = React.createRef();
    this.errorsPanel = React.createRef();
    this.state = { success: false };
  }


  verifyCode() {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const code = this.vCode.current.value;
    let params = { email, code };
    let apiRequest = new ApiRequest("post", "/user/verify-code");
    apiRequest.send(params, (response) => {
      let result = response;
      console.log("result: ", result);
      try {
        if (result.errors) {
          // Handle verification error
          if (result.message == "Un-verified") {
            // Handle unverified account
            this.errorsPanel.current.innerHTML = "Invalid verification code or code expired. Please request a new code.";
          } else {
            this.errorsPanel.current.innerHTML = result.message;
          }
        } else {
          // Verification successful
          this.setState({ success: true });
          // Save token to state
          this.setState({ token: result.data.token });

          setTimeout(() => {
            this.handleUpdatePassword();
          }, 1000);

        }
      }
      catch (e) {
        // Handle api request error
        console.log("error: ", e);
      }
    });
  }

  render() {
    var success = [];
    if (this.state.success) {
      success.push(<div className="errors"
        style={{ fontSize: "18px", marginBottom: "12px" }}
      >Verification Successful</div>);
    }
    return (
      <React.Fragment>
        <div className="container-fluid signup" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ width: '100%' }}>


            <div className="col-md-6 left-panel show-text-on-desktop meta-text-paragraph">
              <p className="auth-intro-text" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


            <div className="col-md-6 right-panel  meta-text-paragraph" >

              <div className="float-right panel">
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/gorilla-logo-large.png"
                    className=" homelogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Create Art, Share Skills!</span>
                </div>
                <div className="login">
                  <span className="h3" style={{ marginBottom: "10px" }}>Update Password</span>
                  <div className="errors" style={{
                    fontSize: "18px",
                    marginBottom: "12px"
                  }} ref={this.errorsPanel}></div>
                  {success}
                  <form>
                    <div className="field">
                      <input type="text" placeholder="Verification Code" ref={this.vCode} />
                      <div style={{
                        width: "90%",

                      }}>
                        <small style={{
                          position: "relative",
                          bottom: "10px",
                          fontSize: "12px",

                        }}>
                          Enter verification code that we have send you on email.
                        </small>
                      </div>
                    </div>
                    <div className="field">
                      <input type="password" placeholder="New Password" ref={this.password} />
                    </div>
                    <div className="field">
                      <input type="password" placeholder="Confirm Password" ref={this.confirmPassword} />
                    </div>
                    <div className="buttons">
                      <button
                        className="btn"
                        // onClick={this.handleUpdatePassword.bind(this)}
                        onClick={this.verifyCode.bind(this)}
                        type="button"
                      >
                        Update Password
                      </button>
                      Didn't receive code? <a href="/reset">Send Again</a>

                    </div>
                    <div>
                    </div>
                  </form>
                </div>
              </div>
            </div>


            <div className="col-md-6 left-panel show-text-on-mobile " >
              <p className="auth-intro-text" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>




          </div>
        </div>
      </React.Fragment>
    );
  }

  handleUpdatePassword() {
    const password = this.password.current.value;
    const confirmPassword = this.confirmPassword.current.value;
    if (password !== confirmPassword) {
      this.errorsPanel.current.innerHTML = "Passwords do not match";
      return;
    }

    const { token } = this.state;
    if (!token) {
      // Handle token not found
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    let params = { email, password, confirm_password: confirmPassword, token };
    let apiRequest = new ApiRequest("post", "/user/update-password");
    apiRequest.send(params, (response) => {
      let result = response.data;
      if (result.errors) {
        // Handle update password error
        this.errorsPanel.current.innerHTML = result.message;
      } else {
        window.location.href = "/login";
        // Password updated successfully
        // Redirect to login page or any other page
      }
    });
  }
}

export default UpdatePassword;
