import React, { Component } from "react";
import ApiRequest from "../libs/request.js";
//import AppleLogin from 'react-apple-login'
import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

class Login extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.errorsPanel = React.createRef();
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  responseGoogle(response) {
    if (response.profileObj) {
      var profile = response.profileObj;
      let params = {
        email: profile.email,
        // social_id: profile.googleId,
        // first_name: profile.givenName,
        // last_name: profile.familyName,
        // social_type: "google",
        provider_id: profile.googleId,
        provider_name: "google",
      };
      let apiRequest = new ApiRequest("post", "/user/social-login");
      apiRequest.send(params, (response) => {
        let result = response.data;

        if (result.message === "Signup Successful.") {
          this.errorsPanel.current.innerHTML = "Signup Successful. Please login now.";
          return;
        }

        if (result.errors) {
          this.setState({ errors: result.message });
        } else {
          localStorage.setItem("gorilla-token", result.token);
          localStorage.setItem("current_user", JSON.stringify(result.user));

          location.href = "/";
        }
      });
    } else {
      //this.setState({errors: "Google Auth Error"});
    }
  }

  responseFacebook(response) {
    if (response.email) {
      var profile = response;
      let params = {
        email: profile.email,
        social_id: profile.id,
        first_name: profile.name.split(" ")[0],
        last_name: profile.name.split(" ")[1],
        social_type: "facebook",
      };
      let apiRequest = new ApiRequest("post", "/user/login");
      apiRequest.send(params, (response) => {
        let result = response.data;
        if (result.errors) {
          this.setState({ errors: result.message });
        } else {
          localStorage.setItem("gorilla-token", result.token);

          location.href = "/";
        }
      });
    } else {
      // this.setState({errors: "Google Auth Error"});
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid signup" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ width: '100%' }}>
         
         
          <div className="col-md-6 left-panel show-text-on-desktop  meta-text-paragraph">
              <p className="auth-intro-text" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>

            

            <div className="col-md-6 right-panel  meta-text-paragraph">

              <div className=" panel" style={{ margin: "auto",marginTop:"50px" }}>
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/logo.png"
                    className=" homelogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Create Art, Share Skills!</span>
                </div>
                <div className="login loginpanel" >
                  <span className="h3" style={{ marginBottom: "10px" }}>
                    Login to
                    <br />
                    Your Account
                  </span>
                  <div className="errors" style={{
                    fontSize: "18px",
                    marginBottom: "12px"

                  }} ref={this.errorsPanel}>
                    {" "}
                  </div>
                  <form>
                    <div className="field">
                      <input type="email" placeholder="Email" ref={this.email} />
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        placeholder="Password"
                        ref={this.password}
                        onKeyUp={this.handleKeyUp.bind(this)}
                      />
                    </div>
                    <div className="field">
                      <a href="/reset">Forgot your password?</a>
                    </div>

                    <div className="buttons">
                      <button
                        className="btn login-btnn"
                        onClick={this.handleLoginClick.bind(this)}
                        type="button"
                      >
                        Log in
                      </button>

                      {/* <GoogleLogin
                    clientId="82784276186-9nrc2ikdgtgha8ee05rqehg7pq1bhjnq.apps.googleusercontent.com"
                    buttonText="Sign in with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  <div style={{marginBottom: "20px"}} /> */}

                      {/* <a href="https://api.gorillafilmapp.com/oauth/apple">
                    <img
                      width="335"
                      src="/images/apple-id-sign-in-with_2x.png"
                    />
                  </a>
                  <br />
                  <GoogleLogin
                    clientId="82784276186-9nrc2ikdgtgha8ee05rqehg7pq1bhjnq.apps.googleusercontent.com"
                    buttonText="Sign in with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  <br />
                  <FacebookLogin
                    appId="956684304693316"
                    autoLoad={false}
                    render={(renderProps) => (
                      <img
                        onClick={renderProps.onClick}
                        className="fb-login"
                        src="/images/fb-login.png"
                      />
                    )}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                  /> */}
                      <span>Not yet a member? <a href="/signup">sign up</a></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>


            <div className="col-md-6 show-text-on-mobile">
              <p className="auth-intro-text" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


          </div>

        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    if (this.state.errors) {
      this.errorsPanel.current.innerHTML = this.state.errors;
    }
  }

  handleKeyUp(e) {
    if (e.keyCode == 13) {
      this.handleLoginClick();
    }
  }

  handleLoginClick() {
    let params = {
      email: this.email.current.value,
      password: this.password.current.value,
    };
    let apiRequest = new ApiRequest("post", "/user/login");
    apiRequest.send(params, (response) => {
      let result = response.data;
      if (response.errors) {
        this.setState({ errors: response.message });
      } else {
        localStorage.setItem("gorilla-token", result.token);
        localStorage.setItem("current_user", JSON.stringify(result.user));

        location.href = "/";
      }
    });
  }
}

export default Login;
