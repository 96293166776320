import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import axios from "axios";
import { validFile } from "../../libs/functions";
import LocationSeach from "../../components/main/LocationSearch";
import Modal from "react-modal";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import GooglePlacesAutoComplete from "../shared/location/location.js";
function DownloadLink(_props) {
  if (_props.resume != null && _props.resume != "") {
    return (
      <div className="location">
        <a href={_props.resume}>
          <u>download</u>
        </a>
      </div>
    );
  } else {
    return <div />;
  }
}
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minHeight: "400px",
    transform: "translate(-50%, -50%)",
    width: "600px",
    padding: "0px",
    borderRadius: "10px",
  },
};

class InfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      reels: [],
      resume: "",
      images: [],
      location: "",
    };
    this.handleResumeFileClick = this.handleResumeFileClick.bind(this);
  }


  selectedLocation(loc) {
    this.setState({ location: loc.label, showModal: false });
  }

  render() {
    var _images = [];
    this.state.images.map((i, idx) => {
      _images.push(
        <span style={{ position: "relative" }}>
          <img key={idx} src={i} height="60" style={{ marginRight: "10px" }} />
          <FaTimes onClick={this.handleFileRemove.bind(this, idx)} style={{
            position: "absolute",
            color: "#ff9500",
            right: "10px",
            fontSize: "12px",
            marginTop: "5px"
          }} />
        </span>);
    });
    var _location = this.state.location ? this.state.location : "";
    return (
      <React.Fragment>
        <div className="col-12 m-0 p-0">
          <div
            style={{ display: "none" }}
            id="result"
            className="alert alert-success"
          >
            Addtional Info Saved
          </div>

          <input
            type="file"
            className="hidden-upload-field"
            name="portfolio[]"
            id="banner-image-field"
            onChange={this.handleFileUpload.bind(this)}
          />
          <img
            src="/images/short-logo.jpg"
            style={{ marginRight: "10px",width:"50px" }}
            onClick={this.handleFileClick.bind(this)}
          />
          {_images}
        </div>

        <div className="col-12 m-0 p-0 mt-2 other">
          <h4>Notification &amp; Location</h4>
          <div
            className="select-field"
            style={{ cursor: "pointer" }}
            onClick={() => this.setState({ showModal: true })}
          >
            Enter your location
            <div className="location form-inline inline-cus-width" >
              {!this.state.showModal ? _location :

                <div style={{
                  width: "100%",
                  position: "relative",
                  bottom: "8px"
                }}
                >

                  <GooglePlacesAutoComplete selectedValue={this.state.location} selectedLocation={this.selectedLocation.bind(this)} />
                </div>
              }
            </div>
          </div>
          <div className="select-field resume">
            <a onClick={this.handleResumeFileClick.bind(this)}>
              Upload your resume
            </a>
            <input
              type="file"
          
              id="resume-file"
              style={{ visibility: "hidden" }}
              onChange={this.handleResumeFileUpload}
            />
            <DownloadLink resume={this.state.resume} />
          </div>
          <button
            type="button"
            onClick={this.handleSave.bind(this)}
            className="g-button full"
          >
            Save
          </button>
          <p>&nbsp;</p>
        </div>
        {/* <Modal
          isOpen={this.state.showModal}
          style={customModalStyles}
          contentLabel="Reviews"
          shouldCloseOnOverlayClick={true}
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <div className="modalHeader">
            {this.state.modalComponent}
            <div className="modalClose">
              <FaTimesCircle
                onClick={() => this.setState({ showModal: false })}
              />
            </div>
          </div>
          <div className="modalContent">
          <GooglePlacesAutoComplete selectedLocation={this.selectedLocation.bind(this)} />

        
          </div>
        </Modal> */}
      </React.Fragment>
    );
  }

  handleSave() {
    var api = new ApiRequest("post", "/user/update-profile");
    api.send({ city: this.state.location }, () => {
      if (this.props.next) {
        window.location.href = this.props.next;
        return;
      }
      window.scrollTo(0, 0);
      document.getElementById("result").style.display = "block";
    });
  }

  handleFileUpload() {
    var formData = new FormData();
    var file = document.querySelector("#banner-image-field");
    formData.append("picture[]", file.files[0]);
    if (!validFile(file, "photo")) {
      return;
    }
    let apiRequest = new ApiRequest("post", "/user/update-additionalinfo");
    apiRequest.send_file(formData, (response) => {
      let result = response.data;
      this.setState({ images: result.data.picture });
    });
  }

  handleFileRemove(id) {
    var formData = new FormData();
    let apiRequest = new ApiRequest("post", "/user/update-picture-remove");
    formData.append("index", id);
    // formData.append("remove_file_ids[]", id);
    apiRequest.send(formData, (response) => {
      let result = response.data;
      console.log("result", result);
      if (result.message == "Image has been deleted successfully") {
        // remove index from images and set to state
        let images = this.state.images;
        images.splice(id, 1);
        this.setState({ images: images });


      }
    });
  }

  handleResumeFileUpload() {
    var formData = new FormData();
    var file = document.querySelector("#resume-file");
    formData.append("user[resume]", file.files[0]);

    let apiRequest = new ApiRequest("post", "/user/update-additionalinfo");
    apiRequest.send_file(formData, () => {
      window.location.reload(); // TODO FIX STATE CHANGE HERE
    });
  }

  handleFileClick() {
    document.getElementById("banner-image-field").click();
  }

  handleResumeFileClick(mode) {
    document.getElementById("resume-file").click();
  }

  handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getPosition.bind(this));
    }
  }

  handleLocationChange(e) {
    var city = document.getElementById("city").value;
    var state = document.getElementById("state").value;

    var api = new ApiRequest("post", "/user/update-profile");
    api.send({ city: city }, () => {
      this.setState({ location: city });
    });
  }

  getPosition(position) {
    const reverseUrl =
      "https://api.opencagedata.com/geocode/v1/json?q=" +
      position.coords.latitude +
      "+" +
      position.coords.longitude +
      "&key=224a5c67e2c5450fbbea8da2270966d1";
    axios.get(reverseUrl).then((response) => {
      var result = {
        city: response.data.results[0].components.city,
        state: response.data.results[0].components.state_code,
      };
      var api = new ApiRequest("post", "/user/update-profile");
      api.send({ city: result.city, state: result.state }, () => {
        this.setState({ location: result });
      });
    });
  }
  componentDidMount() {
    var current_user_id = 0;
    var api = new ApiRequest("get", "/user/view-profile");
    let _state = { location: "", reels: [], resume: "", images: [] };
    api.send({}, (response) => {
      console.log("response", response);
      _state["location"] = response.data.user.city ? response.data.user.city : response.data.user.additional_info ? response.data.user.additional_info.location: "";
      _state["resume"] = response.data.user.additional_info ? response.data.user.additional_info.resume : "";
      current_user_id = response.data.user.id;
      let _reels = [];
      _state["images"] = response.data.user.additional_info ? response.data.user.additional_info.picture : [];
      api.url = "/user/my-reels";
      api.send({}, (response) => {
        if (response.data.reels.length > 0) {
          response.data.reels.map((result) => {
            _reels.push({
              id: result.id,
              reel: result.imdb,
            });
          });
          _state["reels"] = _reels;
        }
      });
      this.setState(_state);
    });
  }
}

export default InfoForm;
