import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import "./films.css";
import Nav from "../main/Nav.js";
import FeedNav from "../main/FeedNav.js";

function FilmList(props) {
    if (props.films.length < 1) {
        return <div></div>;
    }

    const items = props.films.map((f, idx) => (
        <div className="film-item" key={"item-" + idx}>
            <div
                className="image"
                onClick={() => (location.href = "/film-market/edit/" + f.post.id)}
                style={{ backgroundImage: "url(" + Object(f.post.photos[0]).url + ")" }}
                key={idx}
            ></div>
            <div className="image-controls" key={"ctr" + idx}>
                <div className="title">{f.post.body}</div>
                <div className="icons">
                    CREATED {Object(f.post).created_at}
                    <i
                        className="fas fa-trash"
                        onClick={() => props.onDeleteClicked(f.post.id)}
                    />
                </div>
            </div>
        </div>
    ));
    return <div className="film-list">{items}</div>;
}

class AddFilm extends Component {
    constructor(props) {
        super(props);
        this.state = { films: [] };
        this.loadFilms();
    }

    render() {
        return (
            <React.Fragment>
                <Nav user={this.props.user} />
                <div className="feed">
                    <div className="row  feed-container">
                        <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                            <FeedNav user={this.props.user} />
                        </div>
                        <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
                            <div className="row justify-content-center films">
                                <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
                                    <div className="hdr">
                                        <h2>Add Video</h2>
                                    </div>
                                    {/* <FilmList
                                        films={this.state.films || []}
                                        onDeleteClicked={this.handleDelete.bind(this)}
                                    /> */}


                                    <button
                                        type="button"
                                        className="btn g-button"
                                        onClick={this.handleContinue.bind(this)}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    loadFilms() {
        let api = new ApiRequest("get", "film-market");
        api.send({}, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }
            this.setState({ films: response.data.data.posts });
        });
    }

    handleDelete(id) {
        let api = new ApiRequest("delete", "/posts/" + id);
        api.send({}, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }
            this.loadFilms();
        });
    }

    handleContinue() {
        location.href = "/projects";
    }
}

export default AddFilm;