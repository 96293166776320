import React, { Component } from "react";
import Nav from "./Nav";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = { faqtext: "", user: {} };
  }


  render() {
    return (
      <React.Fragment>
        <Nav user={this.state.user} partialNav={true} />
        <div className="feed container-fluid">
          <div className="row" style={{
            margin: "auto",
            justifyContent: "center"
          }}>
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12 feed-center">
                <div>
                  <div>

                    <title>TodaysPainter Privacy Policy</title>

                    <h2> Terms and Conditions</h2>

                    <h3>Updated November 14, 2019</h3>

                    TodaysPainter, LLC (“TodaysPainter,” “Company,” “we” or “us”) welcomes you. We
                    provide the website at www.todayspainter.com (the
                    “Site”) and the mobile application (the “App”), collectively known as the
                    Services (the “Services”) to you, subject to the terms
                    and conditions contained herein (this “T&C”). This T&C governs the
                    relationship between Company and you, the user of the
                    Site and App.

                    1. Legally Binding Agreement; Changes to T&C.
                    1.1 Please read this T&C carefully as it contains legal obligations. You
                    agree that by registering for or using our Services
                    (defined in Section 2 below), or by accessing or using our Site or App,
                    you are entering into a legally binding agreement with
                    TodaysPainter. If you are using or accessing the Services or Site on behalf of
                    your employer, another individual or an entity, such as a
                    corporate entity, trust, partnership or other organization (collectively,
                    “Authorizing Party”), you represent that you are duly
                    authorized by such Authorizing Party to enter into this T&C on behalf of
                    such Authorizing Party (and “you” as used in this T&C
                    refers both to you individually and to the Authorizing Party). You also
                    represent to TodaysPainter that you are of the legal age to form a
                    binding agreement with us and that you are not a person or entity barred
                    from accessing or using the Site or App under the laws
                    of the United States or other applicable jurisdiction. By clicking
                    “***TERM USED ON SITE/APP***” at the time or after you
                    register with us, you acknowledge that you have read and understood the
                    terms and conditions of this T&C and that you agree to
                    be bound by all of its provisions, that you also consent to use electronic
                    signatures, and that you acknowledge your click of the
                    “***TERM USED ON SITE/APP***” button as one such signature. If you do not
                    want to register for an account with us, or
                    you do not agree to any terms of this T&C, do not click “***TERM USED ON
                    SITE/APP***” and do not access or use the
                    Services or Site.
                    1.2 We reserve the right to update or make changes to this T&C from time
                    to time in our sole discretion, and we may notify you
                    of changes by any reasonable means, including without limitation, by
                    notifying you through the email you provide us in your
                    User Profile (defined in Section 2) or by posting the revised version of
                    this T&C on the Site, which changes will become
                    effective immediately upon the transmission of such email or posting on
                    the Site, whichever occurs first. You can determine
                    when this T&C was last revised by referring to the “Updated” legend at the
                    top of this T&C. Please return to this page
                    periodically to ensure familiarity with the most current version of this
                    T&C. Your continued access or use of the Services or Site
                    after any changes to this T&C shall constitute your agreement and consent
                    to such changes. You agree that your use, access and
                    purchase of any Services, or the entering into agreements with
                    third-parties, may be subject to any additional posted guidelines,
                    rules, terms and conditions applicable to such Services (collectively,
                    “Policies”), which are hereby incorporated by reference into
                    this T&C. In the event that any terms or conditions of such Policies
                    contradict or are inconsistent with the terms and conditions
                    of this T&C, such Policies will govern and prevail solely with respect to
                    the specified Services to which they apply.
                    2. Definitions.
                    2.1
                    “Accredited Investor” has the meaning ascribed thereto by the SEC in Rule
                    501 of Regulation D (as amended from time to
                    time) under the U.S. Securities Act of 1933 (the “Act”), or such other
                    rule as may succeed Rule 501 from time to time. For
                    informational purposes only, as of the date hereof, the “Accredited
                    Investor” is defined as:
                    (i)
                    Any bank as defined in section 3(a)(2) of the Act, or any savings and loan
                    association or other institution as defined in
                    section 3(a)(5)(A) of the Act whether acting in its individual or
                    fiduciary capacity; any broker or dealer registered pursuant to
                    section 15 of the Securities Exchange Act of 1934; any insurance company
                    as defined in section 2(a)(13) of the Act; any
                    investment company registered under the Investment Company Act of 1940 or
                    a business development company as defined in
                    section 2(a)(48) of that Act; any Small Business Investment Company
                    licensed by the U.S. Small Business Administration under
                    section 301(c) or (d) of the Small Business Investment Act of 1958; any
                    plan established and maintained by a state, its political
                    subdivisions, or any agency or instrumentality of a state or its political
                    subdivisions, for the benefit of its employees, if such plan
                    has total assets in excess of $5,000,000; any employee benefit plan within
                    the meaning of the Employee Retirement Income
                    Security Act of 1974 if the investment decision is made by a plan
                    fiduciary, as defined in section 3(21) of such act, which is
                    either a bank, savings and loan association, insurance company, or
                    registered investment adviser, or if the employee benefit plan
                    has total assets in excess of $5,000,000 or, if a self-directed plan, with
                    investment decisions made solely by persons that are
                    accredited investors;
                    (ii)
                    Any private business development company as defined in section 202(a)(22)
                    of the Investment Advisers Act of 1940;
                    (iii)
                    Any organization described in section 501(c)(3) of the Internal Revenue
                    Code, corporation, Massachusetts or similar
                    business trust, or partnership, not formed for the specific purpose of
                    acquiring the securities offered, with total assets in excess of
                    $5,000,000;
                    (iv)
                    Any director, executive officer, or general partner of the issuer of the
                    securities being offered or sold, or any director,
                    executive officer, or general partner of a general partner of that issuer;
                    (v)
                    Any natural person whose individual net worth, or joint net worth with
                    that person's spouse, at the time of his purchase
                    exceeds $1,000,000;
                    (vi)
                    Any natural person who had an individual income in excess of $200,000 in
                    each of the two most recent years or joint
                    income with that person's spouse in excess of $300,000 in each of those
                    years and has a reasonable expectation of reaching the
                    same income level in the current year;
                    (vii)
                    Any trust, with total assets in excess of $5,000,000, not formed for the
                    specific purpose of acquiring the securities
                    offered, whose purchase is directed by a sophisticated person as described
                    in Rule 506(b)(2)(ii); and
                    (viii)
                    Any entity in which all of the equity owners are accredited investors.
                    2.2 “Code of Conduct” means the rules governing the conduct by Users when
                    using or accessing any of the Services or Site, as
                    prescribed by TodaysPainter from time to time. A copy of the current Code of
                    Conduct can be found on our website, the contents of
                    which are hereby incorporated by reference into this T&C.
                    2.3 “Company Person” means any employee, officer, director, agent,
                    representative or affiliate of the Company.
                    2.4
                    “Confidential Information” means all Content owned or controlled by a User
                    made available on the Services or Site that is
                    marked or designated by such User or TodaysPainter as “confidential,” that is
                    disclosed under circumstances indicating its confidential
                    nature, or otherwise would be known to be confidential by a reasonable
                    person. If a User, in the Company's reasonable
                    determination, ceases to treat as confidential such User’s Content
                    designated by such User as Confidential Information, or if such
                    Content is disclosed by such User to a third party without any obligations
                    of confidentiality, or if such Content becomes public
                    information through no fault of any receiving User, then such Content
                    shall no longer be deemed Confidential Information for the
                    purposes of this T&C.
                    2.5
                    “Content” means (a) images, text, messages, works of authorship,
                    photographs, graphics, comments, suggestions, video,
                    audio, or other material uploaded, downloaded, appearing or otherwise made
                    available on or through the Services or the Site; and
                    (b) personally identifiable information, data and other information
                    uploaded, downloaded, appearing or otherwise made available
                    on or through the Services or the Site.
                    2.6
                    “Eligible User Profile” means the profile created by the User which may
                    contain the User’s email address, the password
                    used to access the User’s account, payment information, and any other
                    information the Company may require to complete or
                    maintain your registration of an account.
                    2.7 “Filmmaker” means a User who represents one or more film projects
                    through the Services or Site and may (or may not) be
                    interested in procuring investor interest for such film project(s) through
                    the Services or Site.
                    2.8 “Investor” means a User who has indicated an interest in finding
                    investment opportunities offered by Filmmakers through
                    the Services or Site.
                    2.9 “Privacy Policy” means TodaysPainter's policy on how TodaysPainter collects, uses,
                    discloses and manages information and data collected
                    about Users of the Services and Site, which policy may be modified by
                    TodaysPainter from time to time. A copy of the current Privacy
                    Policy can be found on our website.
                    2.10 “Prohibited Activity” means one or more activities designated by
                    TodaysPainter as prohibited from time to time in its sole and
                    absolute discretion. Without limiting the foregoing, the following will be
                    deemed Prohibited Activities:
                    (i)
                    The modification, adaptation, disassembly, de-compilation, translation,
                    reverse engineering or other attempt to
                    discover the source code or structure, sequence and organization of the
                    Services or Site (except where the foregoing is permitted
                    by applicable local law notwithstanding such restrictions, and then only
                    to the extent that such intended activities are disclosed in
                    advance in writing to us);
                    (ii)
                    The use of Services or Site in any manner that could damage, disable,
                    overburden, impair or otherwise interfere with or
                    disrupt the Services, Site or any networks or security systems of Company
                    or its service providers, or otherwise interfere with
                    other Users’ use of the Services or Site;
                    (iii)
                    The removal, obscuring or changing of any copyright, trademark, hyperlink
                    or other proprietary rights notices
                    contained in or on the Services, Site or any Content;
                    (iv)
                    The submission of any Content or material that expresses or implies that
                    such Content or material is sponsored or
                    endorsed by the Company;
                    (v)
                    The use of the Services or Site to violate the security of any computer
                    network or to transfer or store illegal material; or
                    (vi)
                    The use of the Services or the delivery of any Content in violation of any
                    applicable law, including the securities laws,
                    intellectual property laws, right of privacy or publicity laws and any
                    laws of any jurisdiction (including that of the United States
                    of America) applicable to you or TodaysPainter.
                    2.10 “SEC” means the U.S. Securities and Exchange Commission, and/or any
                    successor entity or entities thereto.
                    2.11 “Services” means any and all services made available on the Site,
                    including any information, features, functionality and
                    applications offered on the Site from time to time. Access to certain
                    Services may be based on the type of subscription you have
                    purchased and/or based on the designation of your user type (i.e.
                    Investor, Filmmaker or other user type).
                    2.12 “User” means any user of the Services and/or Site.
                    3. TodaysPainter Services, Related Undertakings and Limitations.
                    3.1
                    Right to use the Services. Subject to your acceptance of, and compliance
                    with, this T&C, we grant you a non-
                    exclusive, revocable, non-assignable, non-sublicenseable, non-transferable
                    right to access and use the Services to which you have
                    subscribed and the Site. The Services and Site are protected by copyright,
                    trademark, and other laws of both the United States
                    and foreign countries. Except as expressly provided herein, nothing in
                    this T&C gives you a right to use our trademarks, trade
                    names, service marks, logos, domain names, or other brand features.
                    TodaysPainter and its licensors reserve all rights not expressly
                    granted in this T&C.
                    3.2 Restrictions and Obligations. You agree that in accessing and using
                    the Services and Site, you agree that you will:
                    (i) not modify, alter, adapt or create a derivative work of any Content of
                    another User, the Services or the Site;
                    (ii) attribute the Site as the source of any information which you obtain
                    from the Site, with such attribution in the form of a
                    human and machine-followable link (a tag) linking back to the page on the
                    Site displaying the original Content which contains
                    such information (or if the original page is not accessible to the
                    recipient or viewer of the information without logging in through
                    an account with us, then you will attribute the Site generally as the
                    source of such information). For purposes of clarity, you shall
                    not have the right to use or disclose any Confidential Information in
                    breach of your confidentiality obligations in Sections 4.2;
                    (iii) upon request by TodaysPainter, make a reasonable effort to update your
                    Content on the Site and/or the Services to the most updated
                    version (and we will similarly make a reasonable effort to update any of
                    your Content on the Site upon our receipt of your written
                    request);
                    (iv) upon request, either by TodaysPainter or by a User who provided the
                    Content, make a reasonable attempt to permanently delete,
                    destroy and erase any Content that has been deleted from the Site (and
                    require that any party to whom such Content has been
                    provided, through multiple tiers, do to the same);
                    (v) will not use the Services or Site, or display any results, Content or
                    information from the use of the Services or Site in any way
                    to compete with TodaysPainter, the Services or the Site;
                    (vi) will not sublicense, transfer, share, lend (via a service bureau or
                    otherwise), distribute or resell to any third party your right to
                    access or use the Services or Site (or any portion thereof);
                    (vii) will not use any data mining features, robots, spiders or similar
                    data gathering and extraction tools in your use of the
                    Services or Site to retrieve, index, “scrape,” “data mine,” or in any way
                    gather content or data from the Services or Site, or
                    otherwise circumvent the navigational structure or presentation of the
                    Services or Site; and
                    (viii) will not circumvent, disable, override or otherwise interfere with
                    any security-related features of the Services or Site, or any
                    other features that prevent or restrict use, copying, data mining or
                    otherwise or enforce the limitations on the use of the Services
                    or Site.
                    3.3 License to Your Content. By uploading or otherwise providing any
                    Content through your use of the Services or Site, whether
                    through your account with us or otherwise, you hereby grant to TodaysPainter a
                    non-exclusive, non-transferable (except as permitted in
                    Section 10.8) royalty-free, fully paid-up, irrevocable, worldwide right
                    and license to use, display, perform, exhibit, modify,
                    reproduce and make available such Content through the Services, App and
                    Site to other Users during the term of this T&C. After
                    the term of this T&C, we shall have the right to maintain a copy of such
                    Content for archival purposes and in order to enforce our
                    rights under this T&C.
                    3.4 Ownership. Subject to the licenses granted in Section 3.3, as between
                    TodaysPainter and you, you shall exclusively own and retain
                    all right, title and interest (including all intellectual and proprietary
                    rights) in and to your Content. As between you and TodaysPainter,
                    TodaysPainter shall exclusively own and retain all right, title and interest
                    (including all intellectual and proprietary rights) in and to the
                    Services and Site, including all Content (other than your Content) and
                    other materials appearing therein. TodaysPainter shall also own
                    any and all metadata related to your Content generated by TodaysPainter’s
                    Services, Site and systems.
                    3.5 Subscriptions; Fees.
                    (i) In order to use and access certain portion of the Services and Site,
                    you will be required to purchase subscriptions which may
                    be offered with a monthly, annual, per transaction or other term. We may
                    offer different levels of subscriptions, which provide
                    different levels of access to the features of the Services and Site. The
                    term of your subscription will commence on the date you
                    purchase such subscription.
                    (ii) For monthly subscriptions, payment will be initiated on a monthly
                    basis and will be paid in advance or on the first day of
                    each such month of your subscription term. For annual subscriptions, you
                    will be charged for the annual term of your
                    subscription at the time you purchase such subscription. For subscriptions
                    on a per transaction basis, the terms will be as
                    described. You hereby authorize us to collect from your payment method all
                    outstanding fees and charges for your account. If
                    any fees and charges are not paid by the due date, TodaysPainter may suspend or
                    terminate your access to the Services and Site, without
                    notice to you, until all amounts due are paid. Additionally, any fees and
                    charges which are not paid by the due date are subject to
                    a late charge of one percent (1%) (or the maximum amount permitted by
                    applicable law, whichever is lower) for each month, or
                    fraction thereof, until the full amount due is paid in full. You are
                    responsible to pay all fees and charges for your subscriptions,
                    and except as described in this T&C, any suspension, termination or
                    disconnection of the Services or Site does not relieve you of
                    the obligation to pay such fees. Fees due for charges based on a per
                    transaction basis are not refundable.
                    (iii) You may cancel your monthly subscription by providing written notice
                    to us of such cancellation. Your subscription will
                    terminate at the end of the month during which you cancelled, and you will
                    be charged the applicable subscription fee for such
                    month. You may cancel your annual subscription by providing written notice
                    to us of such cancellation, which notice must be
                    received by us during the first 180 days of your annual subscription. Upon
                    such cancellation, your subscription will terminate at
                    the end of such 180-day period and you will be entitled to a refund of
                    fifty percent (50%) of the annual subscription fees actually
                    paid to us, less any amounts owed to us. You will not be entitled to any
                    refund of the subscription fee if you elect to cancel your
                    annual subscription after the first 180 days thereof.
                    (iv) If we terminate your subscription without a stated reason, you will
                    only be responsible for the applicable subscription fees,
                    on a pro-rata basis, through the date of the termination of your
                    subscription. If we terminate your subscription for a stated reason,
                    then you will be responsible for the applicable subscription fees through
                    the end your subscription term. “Stated reasons”
                    include the following: (a) we have determined, in our sole discretion,
                    that you have misused the Services or the Site, engaged in
                    suspected fraudulent or illegal activity, engaged in other activity that
                    adversely affects the Services, Site or our network, or other
                    Users’ ability to use the Services or Site; or (b) you have breached this
                    T&C. You understand that Company reserves the right to
                    monitor all uses of the Services and Site, and your compliance with this
                    T&C. Your subscription may be resumed after we have
                    terminated your subscription for a stated reason, but the original term
                    thereof shall not be extended.
                    (v) Other features of the Services or Site may be subject to a fee, such
                    as a submission fee for submitting a project to us. All such
                    fees are non-refundable.
                    3.6 Accredited Investors. We will require all new or prospective Users of
                    TodaysPainter who identify themselves as Investors to execute
                    an Accredited Investor Questionnaire. We have created a questionnaire that
                    is designed to make (prospective) Users review
                    whether they truly are accredited and sophisticated, but we do not verify
                    the representations they make or whether such Users
                    would be deemed accredited under applicable state, federal or
                    international securities laws or other laws. We have also designed
                    the Site so that an Investor is not intended to be introduced to any
                    Filmmaker whose capital raising activities were in process
                    when that Investor becomes a member of TodaysPainter, but we cannot guarantee
                    that our design is foolproof, or that Investors who
                    learn about a Filmmaker's business through TodaysPainter will not find another
                    way to be introduced to such Filmmaker.
                    3.7 Display Filmmakers' Content and Allow Communications with Investors
                    Only as Instructed by the Filmmaker/Investor.
                    When you create an account with TodaysPainter, and at any time thereafter, you
                    will be able to set a variety of filters that are intended to
                    control who is able to see your Confidential Information and what
                    information of other Users will be accessible by you. We will
                    make commercially reasonable efforts not to intentionally violate such
                    filters: provided, however, that we do not guarantee or
                    warranty:
                    (i)
                    that there will never be a software bug or a hacker attack that will allow
                    unauthorized viewing of material or
                    unsolicited contacts to occur; or
                    (ii)
                    that any other User whom you have given access to Confidential Information
                    will not violate the conditions of
                    confidentiality that you and / or the Company have imposed on such User
                    and such Confidential Information.
                    3.8 Reservations and Limitations. Without limiting any right, privilege,
                    limitation or reservation contained elsewhere in this
                    T&C and benefiting the Company, the Company reserves the following rights:
                    (i) TodaysPainter reserves the right, but is not required, to monitor, modify,
                    suppress, delete or control the Content posted via the
                    Services or the Site, and to take any action with respect to such Content
                    at any time and without notice to you or other
                    Users. Our failure to exercise that right, however, does not give you or
                    any other party any right to make a claim against TodaysPainter.
                    (ii)
                    TodaysPainter reserves the right, at any time and from time to time, temporarily
                    or permanently, in whole or in part, to modify,
                    suspend or discontinue the Services or Site in any way and at any time,
                    with or without notice to you. You agree that any notices
                    to you will be deemed provided to you and effective on the date of posting
                    on the Site or on the day of email to the email account
                    we have on file for your account, whichever occurs earlier. You agree that
                    (a) TodaysPainter shall not be liable to you or to any third
                    party for any modification, suspension or discontinuance of the Services
                    or Site, in whole or in part, and (b) your continued use
                    of the Services or site after any changes to the Services or Site have
                    been implemented will be deemed your acceptance of such
                    changes. In the event that the Services are discontinued, you will be
                    responsible for the applicable subscription fees, on a pro-
                    rata basis, through the date of the discontinuance of such Services.
                    (iii) TodaysPainter reserves the right to terminate your access to the Services
                    or Site without notice and, in the event that you have
                    violated this T&C, to pursue additional remedies available to TodaysPainter at
                    law or in equity.
                    (iv) TodaysPainter reserves the right to delete your account for any reason or
                    for no reason at all, and if TodaysPainter deletes your account you
                    will lose all access to any information, connections or other features
                    that may have value to you that may have been associated
                    with your account.
                    (v) TodaysPainter reserves the right to refuse registration of, or cancel, your
                    account in its discretion for any reason or for no reason. In
                    addition, TodaysPainter reserves the right at all times (but will not have an
                    obligation) to remove or refuse to distribute any Content
                    through the Services or Site and to terminate Users or reclaim Usernames.
                    TodaysPainter also reserves the right to access, read,
                    preserve, and disclose any information as it reasonably believes is
                    necessary to: (1) comply with any applicable law, regulation,
                    legal process or governmental request; (2) enforce this T&C (including
                    investigation of potential violations hereof); (3) detect,
                    prevent, or otherwise address fraud, security or technical issues; (4)
                    respond to User support requests; and/or (5) protect the
                    rights, networks, property or safety of TodaysPainter, its Users and the public.
                    (vi) Without limiting the generality of the foregoing, you specifically
                    acknowledge that TodaysPainter has the right to terminate or limit
                    your account for any reason or no reason at all. Any Content that has been
                    uploaded through the Services or Site may be deleted
                    at any time without notice to you. TodaysPainter shall have no liability to any
                    person for any claim based upon its termination of an
                    account or disabling of access to or removal of any Content, including
                    material it believes, in its sole discretion to violate this
                    T&C, regardless of whether the material ultimately is determined to be
                    infringing or otherwise prohibited, and regardless of
                    whether such termination or disabling has the effect of reducing the value
                    of any Content or opportunities that might otherwise
                    have been available to you. By using the Services or Site, you agree that
                    notice to you through an email to the email address you
                    provided in your profile constitutes reasonable efforts to notify you of
                    any removal or disabling if such notice is required.
                    (vii)
                    We are not obligated to display your Content to any other User, nor are we
                    obligated to introduce you to any Filmmaker
                    or Investor.
                    (viii)
                    We are not responsible for conducting due diligence on the Users you meet
                    through the Services or Site. Should we
                    conduct due diligence on one or more Users, this does not change the
                    meaning or effect of the immediately foregoing sentence,
                    nor are we responsible or can be held liable for the outcome of any such
                    due diligence.
                    (ix)
                    We are not responsible for verifying that any Investor is accredited or
                    otherwise authorized or appropriate to invest in
                    your project, or for determining whether any use of TodaysPainter constitutes a
                    general solicitation of securities under U.S. law or the
                    laws of any state or other jurisdiction, including foreign jurisdictions.
                    (x)
                    We do not recommend any films or other ventures for investment or endorse
                    their fitness for investment, verify the
                    information on the Site or in our emails; and we do not claim that any
                    Content provided by Users or the Company is accurate,
                    complete and not misleading.
                    (xi)
                    We are not obligated to maintain the confidentiality of any Content you
                    give us, other than with respect to Confidential
                    Information, and with respect to Confidential Information, we are not
                    obligated to protect it other than by designating it as such
                    and putting in place reasonable technological measures that we have
                    determined in good faith to secure the confidentiality of
                    such Confidential Information. However, we cannot guarantee complete
                    security; specifically, we cannot and do not guarantee
                    that our measures will prevent all unauthorized access and use of
                    Confidential Information, nor do we guarantee that the
                    technology will never fail.
                    (xii)
                    We have no obligation to monitor or enforce any intellectual property
                    rights that may be associated with Content you
                    provide to us.
                    (xiii)
                    In the event that we invest in any film at any stage of development, we
                    are not obligated to make that investment
                    opportunity available to anyone else.
                    (xiv)
                    The Company does not endorse the Content found through the Services, the
                    Site or external sites that may be linked to
                    or from the Site and, therefore, the Company specifically disclaims any
                    responsibility with regard thereto.
                    (xv)
                    The Company has no obligation to accept, display, review, monitor, or
                    maintain any Content submitted by Users, User
                    forum posts, commentary, ratings or compliments. We have the right to
                    delete, suppress and modify Content or comments from
                    the Services or Site without notice for any reason at any time. The
                    Company may move, re-format, edit, alter, distort, remove or
                    refuse to exploit Content or comments without notice to you and without
                    liability. Notwithstanding the foregoing rights, the
                    Company reserves the right to treat both Content and comments as Content
                    stored at the direction of Users for which the
                    Company will not exercise editorial control except as required to enforce
                    the rights of third parties and applicable Content
                    restrictions below when violations are brought to the Company's attention.
                    (xvi)
                    The Services or Site may contain or deliver advertising and sponsorships.
                    Advertisers and sponsors are, but the
                    Company is not, responsible for ensuring that material submitted for
                    inclusion is accurate and complies with applicable laws. We
                    are not responsible for the illegality or any error, inaccuracy or problem
                    in the advertiser's or sponsor's Content.
                    3.9 Compliance with Law. In using and accessing the Site and Services, you
                    agree to comply with all applicable federal, state,
                    local and international laws and regulations, including privacy laws, and
                    export and import laws, regulations, orders or other
                    restrictions administered by the United States Commerce Department's
                    Bureau of Export Administration, the United States
                    Department of Treasury's Office of Foreign Assets Control or any other
                    applicable government agency.
                    4. Your Obligations.
                    4.1 Requirements of Membership. You have the right, authority, and
                    capacity to enter into this T&C on your own behalf and on
                    behalf of any Authorizing Party on whose behalf, or in whose interest, you
                    are acting and to abide by all of the terms and
                    conditions contained herein, and that if any aspect of your access or use
                    of the Services or Site violates any provisions of law to
                    which you or the Services or Site are subject, you will cease all access
                    and use of the Services and Site and close your account if
                    deemed necessary or desirable by TodaysPainter.
                    4.2 Confidentiality Obligations.
                    (i) In accessing and using the Services and Site, based on the type of
                    subscription you have purchased and/or based on the
                    designation of your user type, you may be given access to the Confidential
                    Information of one or more other Users
                    (“Disclosers”). You agree (a) to hold the Confidential Information of
                    Disclosers in strict confidence and to take all necessary
                    precautions (but in no event less than reasonable care) to protect such
                    Confidential Information from unauthorized disclosure,
                    access or use; (b) not to disclose or provide access to any such
                    Confidential Information or any information derived therefrom to
                    any person other than Authorized Persons (defined below); (c) not to use
                    such Confidential Information except for the sole
                    purpose of determining whether you (and not any other party) will enter
                    into a business transaction with the Discloser (the
                    “Purpose”). For purposes of clarity, you may not disclose a Discloser’s
                    Confidential Information to another party for the
                    purpose of such party’s determining whether to enter into a business
                    transaction with the Discloser, but only for the purpose of
                    determining whether you will enter into a business transaction with the
                    Discloser. If any other party wishes to obtain access to
                    any Confidential Information of a Discloser, such third party shall not
                    have the right to obtain such information through you, and
                    may be required to become a User of the Services and Site and to enter
                    into this T&C in order to obtain access to such
                    Confidential Information directly from the Discloser. As used in this T&C,
                    “Authorized Persons” means your accountants,
                    attorneys, financial advisors, banks, and sources of financing who are
                    bound by confidentiality obligations no less stringent than
                    those in this Section 4.2 to maintain the confidentiality of Discloser’s
                    Confidential Information and who need to have access to
                    such Confidential Information for the Purpose.
                    (ii) In addition, you may disclose such Confidential Information if you
                    are legally compelled to do so pursuant to a written court
                    order, subpoena, regulatory demand, or process of law, provided that prior
                    to any such compelled disclosure, you shall (a) unless
                    prohibited by applicable law, promptly notify the Discloser of such
                    compelled disclosure, (b) fully cooperate with the Discloser
                    (at Discloser’s expense) in protecting against any such disclosure and/or
                    obtaining a protective order narrowing the scope of such
                    disclosure and/or use of the Confidential Information, and (c) solely
                    disclose the portion of the Confidential Information
                    compelled to be disclosed and solely to the party entitled to receive it.
                    Any such compelled disclosure shall not otherwise affect
                    your obligations hereunder with respect to the Discloser’s Confidential
                    Information so disclosed.
                    (iii) You agree that solely with respect to a Discloser’s Confidential
                    Information, such Discloser shall be an intended third party
                    beneficiary of this Section 4.2 of this T&C and shall have the right to
                    enforce the terms of this Section 4.2 directly against you,
                    without first requiring that TodaysPainter take or join any action to enforce
                    this Section 4.2 against you. You also agree, as a Discloser
                    of Confidential Information, that TodaysPainter shall have the right in its sole
                    discretion, but shall not have any obligation, to enforce
                    this Section 4.2 against any Users to whom you provide your Confidential
                    Information. You agree that, due to the unique nature
                    of the Confidential Information of Disclosers, the unauthorized disclosure
                    or use of such Confidential Information will cause
                    irreparable harm and significant injury to such Disclosers, the extent of
                    which will be difficult to ascertain and for which there
                    will be no adequate remedy at law. Accordingly, you agree that Disclosers
                    and TodaysPainter, in addition to any other available
                    remedies, shall have the right to seek an immediate injunction and other
                    equitable relief enjoining any breach or threatened
                    breach of your obligations under this Section 4.2 with respect to their
                    Confidential Information, without the necessity of posting
                    any bond or other security.
                    (iv) You agree that at any time requested by the Discloser, you will
                    destroy and permanently delete all Confidential Information
                    of such Discloser (and all copies and extracts thereof) in your possession
                    or control or as otherwise disclosed to Authorized
                    Persons, and shall certify to such Discloser that you and your Authorized
                    Persons have completed such destruction and deletion
                    within ten (10) days after your receipt of request from such Discloser to
                    so destroy and delete such Confidential Information.
                    4.3 Minimum Age. If you register as an Investor, you certify that you are
                    at least 18 years old. If you register as anything other
                    than an Investor, you certify that you are at least 13 years old, and if
                    you are less than 18 years old, your parent or legal guardian
                    has established and authorized your account on your behalf with us.
                    4.4 Eligible User Profile. You agree that you will maintain an Eligible
                    User Profile at all times. You shall be solely responsible
                    for maintaining the confidentiality of your username and password and
                    agree not to transfer, share, disclose or resell your access
                    to the Services or Site, to any third party. You will also update your
                    Eligible User Profile from time to time so that it remains
                    true, correct and complete. You agree to immediately notify Company of any
                    unauthorized use of your username, password
                    and/or account, or any other breach of security related to your account,
                    the Services or the Site, and to ensure that you “log off”
                    and exit from your account at the end of each session. We are not liable
                    for any loss or damage arising from your failure to
                    comply with any of the foregoing obligations. You are entirely responsible
                    for all activities taking place through your account,
                    whether or not you are the individual who undertakes such activities. You
                    agree that an Eligible User Profile excludes:
                    (i)
                    Any use of a false name or an email address owned or controlled by another
                    person with the intent to impersonate that
                    person or for any other reason;
                    (ii)
                    Any use within a username if a name subject to any rights of a person
                    other than yourself without appropriate
                    authorization; or
                    (iii)
                    Any other submission of false or misleading information to the Company.
                    4.5 Professional Conduct. You will conduct yourself in a professional and
                    respectful manner in all your interactions with TodaysPainter
                    and with any other User.
                    4.6 Requirements related to Content on TodaysPainter:
                    (i)
                    You have only provided, and will only provide, TodaysPainter with Content that
                    you have a right to (1) provide to TodaysPainter and
                    to (2) allow TodaysPainter to display, publish and otherwise make available
                    through the Services and Site. By submitting any Content
                    to TodaysPainter, you represent and warrant that you have all the necessary
                    rights, whether under copyrights, trademarks, trade secrets,
                    patents or other intellectual property, to grant the rights granted to
                    TodaysPainter and to otherwise display, publish and otherwise make
                    your Content available through the Services and Site as contemplated in
                    this T&C. You also represent and warrant that your
                    Content complies with all applicable laws, rules and regulations. You
                    understand and agree that your Content may be
                    republished by Users or other third parties and if you do not have the
                    right to submit Content for such use, it may subject you to
                    liability. TodaysPainter will not be responsible or liable for any use of your
                    Content by TodaysPainter in accordance with this T&C.
                    (ii)
                    You understand that TodaysPainter: (1) does not endorse, support, represent or
                    guarantee the completeness, truthfulness,
                    accuracy, or reliability of any Content or communications displayed,
                    published or otherwise made available through the Services
                    or Site; and (2) does not endorse any opinions expressed through the
                    Services or Site. It is your responsibility to verify the
                    completeness, truthfulness, accuracy, and reliability of any Content
                    before making any decision of material consequence,
                    including investment decisions, or relying on such Content for any other
                    reason. TodaysPainter makes no representations or warranties
                    with respect to any Content and shall not be liable for any and all use
                    thereof by you or any other party.
                    (iii)
                    You understand that by using the Services or Site, you may be exposed to
                    Content that you might deem offensive,
                    harmful, inaccurate or otherwise inappropriate, and you agree that TodaysPainter
                    shall have no liability to you or any other party for any
                    such Content.
                    (iv)
                    You understand and agree that the Services or Site may include
                    advertisements or other Content, which may be targeted
                    to the Content or information on the Services or Site, queries made
                    through the Services or Site, or other information, and that
                    TodaysPainter shall have no liability to you or any other party for the
                    placement of advertising or similar Content on the Services or Site,
                    or in connection with the display of Content or information from the
                    Services or Site, whether submitted by you or others.
                    4.7 Requirements related to Investments:
                    (i)
                    You understand and agree that TodaysPainter is not, by providing the Services or
                    Site, providing investment, legal, tax,
                    financial, accounting or other advice to you or any other party.
                    (ii)
                    TodaysPainter is not acting as an advisor or fiduciary in any respect in
                    connection with Content. You will use your own
                    independent judgment before making any decision to invest or to accept an
                    investment.
                    (iii)
                    You are solely responsible at all times for complying with applicable law
                    regarding any transaction, including without
                    limitation the determination of whether any investor is an Accredited
                    Investor and whether any investment complies with the
                    terms of applicable law, including applicable securities laws (whether the
                    law of a U.S. state, or the law of any foreign
                    government with jurisdiction over you or any Investor).
                    (iv)
                    You have obtained, and/or will obtain, such professional advice as is
                    appropriate to protect your interests, including
                    legal, tax, accounting and other advice and to comply with applicable law.
                    4.8 Using the Services.
                    (i)
                    You have not violated, and will not violate, TodaysPainter's Code of Conduct as
                    it exists and is updated from time to time.
                    (ii)
                    You have not engaged in, and will not engage in, any Prohibited Activity.
                    (iii)
                    You acknowledge that any communication from TodaysPainter does not constitute
                    (a) an evaluation, confirmation,
                    endorsement or verification of any User’s or other party’s statements, (b)
                    a recommendation for any investment, or (c) a
                    representation or warranty by TodaysPainter or any Company Person with respect
                    to any investments.
                    (iv)
                    You will not claim any ownership right in any Content displayed on,
                    published by or otherwise available through the
                    Services or Site, other than the Content that you own and which you have
                    provided through the Services or Site.
                    (v)
                    You will not copy or distribute any Content of any other Users except as
                    specifically permitted pursuant to this T&C.
                    (vi)
                    You will not attempt to access, view or alter any source code or object
                    code of the Services or the Site.
                    (vii)
                    You will not use the Services or Site to market services, particularly
                    investment advisory services, that might cause
                    TodaysPainter to have to register as a broker dealer with the SEC, or any other
                    federal or state government agency, or to be treated as an
                    underwriter.
                    4.9 Indemnification. You will hold us harmless against any liability that
                    we may incur as a result of your use of the Services or
                    Site, in accordance with the terms set out in this Sections 4.9.
                    (i)
                    Indemnity. You agree to indemnify and hold the Company and each Company
                    Person harmless from and against any
                    damages, expenses (including reasonable costs and attorneys' fees),
                    liability, causes of action, claims, demands, or settlements
                    arising out of or related to: (a) your access to or use of the Services or
                    Site, (b) the violation of this T&C by you, (c) the
                    infringement by you, or any third party using your account, of any
                    intellectual property rights or other right (including claims
                    related to defamation, invasion of privacy, or other violation of a
                    person's rights) of any person or entity, or (d) any Content made
                    available by you through the Services or Site. Your obligations under the
                    foregoing indemnity may not be offset against any
                    other claim you may have against the Company or any Company Person. You
                    remain solely responsible for all Content that you
                    upload, post, email, transmit, or otherwise disseminate using, or in
                    connection with, the Services or Site. You agree that the
                    provisions in this paragraph will survive any termination of your
                    account(s) and any or all of the Services.
                    (ii)
                    Release. You hereby forever waive any claims you may have against, and
                    irrevocably release, TodaysPainter and/or any
                    Company Person, that are in any way related to the Services, the Site or
                    your use of Content offered through the Services or Site,
                    including any recommendations or referrals you may receive as a result of
                    your registration with TodaysPainter. You are solely
                    responsible for your use of the Services and Site, for any Content you
                    provide or use, and for any consequences thereof, including
                    the use of your Content by other Users and third parties.
                    (iii)
                    TodaysPainter Employees and Affiliates. You understand that TodaysPainter Employees or
                    its Affiliates may participate in the
                    Services as Filmmakers, Investors and other Users, and that TodaysPainter is not
                    responsible for any of their activities, including
                    statements or other information in any emails or other communications such
                    individuals make in their individual capacity.
                    4.10 Matters Related to Licensing / Promotion.
                    (i)
                    Under the license granted by you in Section 3.3, you hereby grant to us a
                    license to publish all the Content you upload
                    to the Services and Site, including any comments or other forum posts you
                    may offer on the Site in order to provide the
                    Services. Your Content will be viewable by other Users and through third
                    party services and websites. You should only provide
                    Content that you are comfortable sharing on those terms. You hereby
                    represent that you understand and assume the risks
                    associated therewith.
                    (ii)
                    You hereby ratify, and consent to, any action that may be taken with
                    respect to your Content under any “droit moral” or
                    similar rights you may have under applicable law (“Moral Rights”), and
                    agree to waive and not to assert any Moral Rights with
                    respect to any Content you provide to us. You further agree that you will
                    confirm any such ratifications, consents and
                    agreements in writing from time to time as requested by us.
                    (iii)
                    TodaysPainter may wish to promote you, your company and other professional
                    affiliation(s) or your film(s) to the TodaysPainter
                    community from time to time. You agree and acknowledge that TodaysPainter may
                    undertake such promotional activities, by including
                    information relating to you, your company and other professional
                    affiliation(s) as well as your film(s) on the Site, in general
                    emails to Users, on our blog(s), on outside social networks (including,
                    but not limited to, Facebook and/or Twitter), and in
                    TodaysPainter newsletters; provided that we undertake to restrict such
                    promotional activities to conform to the settings you control via
                    the Site or App. If you are using TodaysPainter for fundraising purposes, we
                    will make commercially reasonable efforts to avoid
                    promoting your film in a manner that would result in a general
                    solicitation, as such term is construed in respect of private
                    placement regulations existing from time to time under US securities laws.
                    (iv)
                    You understand and agree that the Company is not obligated to compensate
                    you, or to cause any other party to
                    compensate you, with respect to your activities on TodaysPainter, or to feature
                    or otherwise display your Content through any of our
                    Services on any web page.
                    (v)
                    You understand and agree that neither this T&C nor the provision of the
                    Services or Site entitles you to any support,
                    upgrade, update, add-on patch, enhancement, or fix for the Services or
                    Site (collectively, “Updates”). The Company may,
                    however, occasionally provide automatic Updates to the Services in its
                    sole and absolute discretion (and without any advance
                    notification). Any such Updates for the Services shall become part of the
                    Services and subject to this T&C. Failing to install
                    updates of the Services or Site may result in some or all services
                    becoming unusable by you or others.
                    (vi)
                    If you operate, manage or otherwise control a search engine or robot, or
                    you republish a significant fraction of TodaysPainter
                    Content (as we may determine in our sole and absolute discretion), you
                    must follow these rules: (a) you must use a descriptive
                    user agent header; (b) you must follow robots.txt at all times; and (c)
                    you must make it clear how to contact you, either in your
                    user agent string, or on your website, if you have one.
                    4.11 Comments, Feedback, Suggestions, Ideas, And Other Submissions. The
                    Services or Site may invite you to chat or
                    participate in blogs, message boards, online forums and other
                    functionality and may provide you with the opportunity to create,
                    submit, post, display, transmit, perform, publish, distribute or broadcast
                    Content to the Company, the Services and/or the
                    Site. Any such Content will be treated as non-confidential and
                    non-proprietary, except if such Content is clearly identified and
                    treated by you and other Users as Confidential Information. All comments,
                    feedback, suggestions, ideas, forum posts and other
                    submissions disclosed, submitted, or offered to the Company in connection
                    with the use of the Services, the Site or otherwise and
                    any chat, blog, message board, online forum, text, email or other
                    communication with the Company shall be deemed to be part of
                    your Content submitted to Company.
                    4.12
                    Application Programming Interface (API). We may make available one or more
                    APIs for interacting with the Services
                    or Site. Your use of any TodaysPainter API is subject to this T&C and the
                    TodaysPainter API Rules (which will become part of this T&C),
                    which will be posted at such time as we may make such APIs available
                    4.13
                    No Partnership or Agency. It is expressly understood and agreed that
                    neither this T&C nor anything in it shall
                    constitute or be deemed to establish a partnership, agency relationship or
                    joint venture between the Company (or any of its
                    offices, employees or agent) and you (or any of its offices, employees,
                    agents or shareholders). Furthermore, neither the User nor
                    any of its offices, employees or agents shall be authorized to make
                    representations or take any other action whatsoever on behalf
                    of TodaysPainter. In accessing or using the Services or Site, no User will have
                    no authority to bind TodaysPainter in any way and nor will have
                    the right or authority to make any representations to prospective users,
                    customers or other persons on behalf of TodaysPainter.
                    5. Privacy and Protection of Personal Information.
                    5.1 TodaysPainter values your privacy. Please review our Privacy Policy, which
                    may be updated from time to time, to learn more
                    about how we collect and use information about you.
                    5.2 The Company may collect and collate a variety of information regarding
                    the use of the Services or the Site. The Company
                    is the sole owner of all such information it collects. By using the
                    Services or Site you consent to the transfer of your information
                    to the United States and/or other countries for storage, processing and
                    use by TodaysPainter as described in our Privact Policy.
                    5.3 The Company uses industry-standard security measures to protect the
                    loss, misuse and alteration of the information under our
                    control. Although we make good faith efforts to store any non-public
                    information provided to us through the Services or Site, or
                    otherwise collected by the Company in a secure operating environment that
                    is not available to the public, we cannot guarantee
                    complete security. We cannot and do not guarantee that our security
                    measures will prevent third party “hackers” from illegally
                    accessing our site and obtaining access to Content or information thereon.
                    6. Disputes with Others.
                    We reserve the right, but have no obligation, to monitor and/or manage
                    disputes between you and other Users. If you have a
                    dispute with another User, you hereby release the Company, and hereby
                    agree to indemnify the Company, from and against all
                    claims, demands, damages (actual and consequential) and other liabilities
                    of every kind and nature, known and unknown, arising
                    out of or in any way connected with such dispute.
                    7. Disclaimers, Limitations, Waivers of Liability.
                    7.1 YOU EXPRESSLY AGREE THAT ACCESS TO AND USE OF THE SERVICES AND SITE IS
                    AT YOUR SOLE RISK,
                    AND THAT THE SERVICES AND SITE ARE PROVIDED ON AN “AS IS” AND “WHERE IS”
                    BASIS WITHOUT
                    WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                    PERMISSIBLE UNDER
                    APPLICABLE LAW, COMPANY AND ITS AFFILIATES OR SUBSIDIARIES, AND THEIR
                    DIRECTORS, EMPLOYEES,
                    AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS, DISTRIBUTORS, LICENSEES
                    OR LICENSORS
                    (COLLECTIVELY, “COMPANY PARTIES”) DISCLAIM ALL REPRESENTATIONS AND
                    WARRANTIES, EXPRESS OR
                    IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES
                    OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    NON-INFRINGEMENT, FREEDOM FROM
                    COMPUTER VIRUSES AND ALL WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING,
                    COURSE OF
                    PERFORMANCE OR USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, NEITHER THE
                    COMPANY NOR
                    THE COMPANY PARTIES PROVIDE ANY WARRANTY AS TO THE RELIABILITY, ACCURACY,
                    TIMELINESS,
                    USEFULNESS, ADEQUACY, COMPLETENESS OR SUITABILITY OF THE SERVICES OR SITE.
                    YOU FURTHER
                    SPECIFICALLY ACKNOWLEDGE THAT THE COMPANY PARTIES ARE NOT LIABLE, AND YOU
                    AGREE NOT TO
                    SEEK TO HOLD THE COMPANY PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES,
                    INCLUDING OTHER
                    USERS OF THE SERVICES AND SITE AND OPERATORS OF EXTERNAL SITES.
                    7.3 The Company is not responsible for any technical malfunction or other
                    problems of any telephone network or service,
                    computer systems, servers or providers, computer or mobile phone
                    equipment, software, failure of email or players on account of
                    technical problems or traffic congestion on the internet or at any site or
                    combination thereof, including injury or damage to your
                    or to any other person's computer, mobile phone, or other hardware or
                    software, related to or resulting from using or downloading
                    materials in connection with the web and/or in connection with the
                    Services, including any mobile software. Under no
                    circumstances will the Company be responsible for any loss or damage,
                    including any loss or damage to any Content or personal
                    injury or death, resulting from anyone's use of any Services or the Site,
                    any third party applications, software or Content posted
                    on or through the Services or Site, or transmitted to Users, or any
                    interactions between Users of the Services or Site, whether
                    online or offline.
                    7.4 We make no warranty and disclaim all responsibility and liability for
                    the completeness, accuracy, availability, timeliness,
                    security or reliability of the Services, the Site or any Content thereon
                    or any Content you receive as a result of your relationship
                    with TodaysPainter or other Users. TodaysPainter will not be responsible or liable for
                    any harm to your computer system, loss of data, or other
                    harm that results from your access to or use of the Services, the Site or
                    any Content. You also agree that TodaysPainter has no
                    responsibility or liability for the deletion of, or the failure to store
                    or to transmit, any Content and other communications
                    maintained by the Services or the Site. No advice or information, whether
                    oral or written, obtained from TodaysPainter or through the
                    Services or Site will create any warranty not expressly made herein.
                    7.5 UNDER NO CIRCUMSTANCES WILL COMPANY OR ANY COMPANY PARTY BE LIABLE FOR
                    ANY INDIRECT,
                    INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY
                    KIND, UNDER ANY
                    CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT OR OTHER THEORY, INCLUDING
                    WITHOUT LIMITATION,
                    DAMAGES FOR COST OF SUBSTITUTE PROCUREMENT, LOSS OF PROFITS, USE OR DATA,
                    LOSS OF OTHER
                    INTANGIBLES, LOSS OF SECURITY OF INFORMATION YOU HAVE PROVIDED IN
                    CONNECTION WITH YOUR USE
                    OF THE SERVICES or SITE OR UNAUTHORIZED INTERCEPTION OF ANY SUCH
                    INFORMATION BY THIRD
                    PARTIES, OR COST OF RECOVERY OR OTHER DAMAGES, EVEN IF ADVISED IN ADVANCE
                    OF SUCH DAMAGES
                    OR LOSSES. NOTWITHSTANDING ANYTHING ELSE IN THIS T&C, COMPANY’S AND
                    COMPANY PARTIES’
                    AGGREGATE LIABILITY ARISING OUT OF OR RELATING IN ANY WAY TO THIS T&C, THE
                    SERVICES OR SITE,
                    UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT OR OTHER THEORY,
                    SHALL NOT EXCEED
                    THE FEES ACTUALLY PAID BY YOU TO COMPANY DURING THE THREE (3) MONTH period
                    PRECEDING THE
                    DATE ON WHICH COMPANY RECEIVES NOTICE OF A CLAIM FROM YOU. THE EXISTENCE
                    OF ONE OR MORE
                    CLAIMS WILL NOT ENLARGE THIS LIMIT. YOU ACKNOWLEDGE AND AGREE THAT THIS
                    SECTION 7 IS AN
                    ESSENTIAL ELEMENT OF THIS T&C.
                    8. Term and Termination.
                    Unless terminated by the Company, this T&C will remain in full force and
                    effect while you use the Site, any of the Services or
                    maintain an account with TodaysPainter. You may terminate this T&C at any time
                    by deleting all Content of other Users, terminating
                    your account and subscriptions (if any) with us, and ceasing to use the
                    Services and Site. The Company may terminate this T&C
                    in the event you violate any provision of this T&C. Upon termination of
                    this T&C for any reason or no reason, you shall destroy
                    and remove from all computers, and other storage media all Content
                    (including, without limitation, copies of any intellectual
                    property) owned by the Company or any other User of the Services or Site
                    that you received as a result of your use of the
                    Services or Site. Your representations and warranties in this T&C and the
                    provisions of Sections 3, 4, 7, 8 and 10 and any other
                    provision which by their nature are designed to survive termination or
                    expiration of this T&C shall survive any termination or
                    expiration of this T&C.
                    9. Copyright Policy.
                    9.1 TodaysPainter respects the intellectual property rights of others and
                    expects Users of the Services and Site to do the same. We will
                    respond to notices of alleged copyright infringement that comply with
                    applicable law and are properly provided to us. The
                    Digital Millennium Copyright Act (“DMCA”) provides recourse to copyright
                    owners who believe that their rights under the
                    United States Copyright Act have been infringed by acts of third parties
                    over the Internet. If you believe that your copyrighted
                    work has been used without your authorization and is available on or in
                    the Services or Site in a way that may constitute
                    copyright infringement, you may provide notice of your claim to the
                    Company as described in this Section 9.
                    9.2 If you believe that any material on the Services or Site violates your
                    rights in your copyrighted work, please notify the
                    Company as soon as possible by sending an email to us at
                    copyrightviolations@todayspainter.com, or by mailing a letter to the copyright
                    agent (listed below) containing the following information in accordance
                    with the DMCA:
                    (i)
                    a physical or electronic signature of the copyright owner or a person
                    authorized to act on their behalf;
                    (ii)
                    identification of the copyrighted work claimed to have been infringed;
                    (iii)
                    identification of the material that is claimed to be infringing or to be
                    the subject of infringing activity and that is to be
                    removed or access to which is to be disabled, and information reasonably
                    sufficient to permit us to locate the material;
                    (iv)
                    your contact information, including your address, telephone number, and an
                    email address;
                    (v)
                    a statement by you that you have a good faith belief that use of the
                    material in the manner complained of is not
                    authorized by the copyright owner, its agent, or the law; and
                    (vi)
                    a statement that the information in the notification is accurate, and,
                    under penalty of perjury, that you are authorized to
                    act on behalf of the copyright owner.
                    9.3 Our designated copyright agent for notice of alleged copyright
                    infringement or other legal notices regarding Content
                    appearing on the Services or Site is:
                    Email: copyrighviolationst@todayspainter.com
                    Please note that in addition to being forwarded to the person who provided
                    the allegedly illegal Content, we may send a copy of
                    your notice (with your personal information removed) to Chilling Effects
                    (www.chillingeffects.org) for publication and/or
                    annotation. You can see an example of such a publication at
                    http://www.chillingeffects.org/fairuse/notice.cgi. A link to your
                    published notice will be displayed on the Site in place of the removed
                    Content.
                    9.4 We reserve the right to remove Content alleged to be infringing or
                    otherwise illegal without prior notice and at our sole
                    discretion. In appropriate circumstances, TodaysPainter will also terminate a
                    User's account if the User is, or is likely to be, a repeat
                    infringer (as determined by TodaysPainter in its sole and absolute discretion).
                    10. Miscellaneous.
                    10.1
                    Governing Law/Resolution Of Disputes. This T&C shall be governed by and
                    construed in accordance with the
                    internal laws of the United States and the State of Mississippi governing
                    contracts entered into and to be fully performed in the
                    State of Mississippi(i.e., without regard to conflict of law's provisions)
                    regardless of your location. With respect to any disputes
                    or claims not subject to informal dispute resolution or arbitration (as
                    set forth below), you agree not to commence or prosecute
                    any action in connection therewith other than in the state and federal
                    courts located in the city of Oxford state of Mississippi, and
                    you hereby consent to, and waive all defenses of lack of personal
                    jurisdiction and forum non conveniens with respect to, venue
                    and jurisdiction in the state and federal courts located in Mississippi.
                    The parties specifically disclaim application of the
                    Convention on Contracts for the International Sale of Goods.
                    10.2 Injunctive Relief. You acknowledge that the rights granted and
                    obligations made hereunder to the Company are of a
                    unique and irreplaceable nature, the loss of which shall irreparably harm
                    the Company and which cannot be replaced by monetary
                    damages alone so that the Company shall be entitled to injunctive or other
                    equitable relief (without the obligations of posting any
                    bond or surety) in the event of any breach or anticipatory breach by you.
                    You irrevocably waive all rights to seek injunctive or
                    other equitable relief and agree to limit your claims to claims for
                    monetary damages (if any).
                    10.3 Informal Negotiations. To expedite resolution and control the cost of
                    any dispute, controversy or claim related to this T&C
                    (“Dispute”), you and the Company agree to first attempt to negotiate any
                    Dispute (except those Disputes expressly provided
                    below) informally for at least thirty (30) days before initiating any
                    arbitration or court proceeding. Such informal negotiations
                    commence upon written notice from one person to the other. You will send
                    your notice to legal@todayspainter.com .
                    10.4 Binding Arbitration. If you and the Company are unable to resolve a
                    Dispute through informal negotiations, either you or
                    the Company may elect to have the Dispute (except those Disputes expressly
                    excluded below) finally and exclusively resolved by
                    binding arbitration conducted in the City of Oxford, Mississippi. Any
                    election to arbitrate by one party shall be final and binding
                    on the other. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE
                    THE RIGHT TO SUE IN
                    COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and
                    conducted under the Commercial Arbitration
                    Rules of the American Arbitration Association (“AAA”) and, where
                    appropriate, the AAA's Supplementary Procedures for
                    Consumer Related Disputes (“AAA Consumer Rules”), both of which are
                    available at the AAA website www.adr.org. The
                    determination of whether a Dispute is subject to arbitration shall be
                    governed by the Federal Arbitration Act and determined by a
                    court rather than an arbitrator. Your arbitration fees and your share of
                    arbitrator compensation shall be governed by the AAA
                    Rules and, where appropriate, limited by the AAA Consumer Rules. If such
                    costs are determined by the arbitrator to be
                    excessive, the Company will pay all arbitration fees and expenses. The
                    arbitration may be conducted in person, through the
                    submission of documents, by phone or online. The arbitrator will make a
                    decision in writing, but need not provide a statement of
                    reasons unless requested by a party. The arbitrator must follow applicable
                    law, and any award may be challenged if the arbitrator
                    fails to do so. Except as otherwise provided in this T&C, you and the
                    Company may litigate in court to compel arbitration, stay
                    proceedings pending arbitration, or to confirm, modify, vacate or enter
                    judgment on the award entered by the arbitrator.
                    10.5 Restrictions in Respect of Arbitration. You and the Company agree
                    that any arbitration shall be limited to the Dispute
                    between the Company and you individually. To the full extent permitted by
                    law:
                    (i)
                    no arbitration shall be joined with any other;
                    (ii)
                    there is no right or authority for any Dispute to be arbitrated on a
                    class-action basis or to utilize class action procedures;
                    and
                    (iii)
                    there is no right or authority for any Dispute to be brought in a
                    purported representative capacity on behalf of the
                    general public or any other persons.
                    10.6 Exceptions to Informal Negotiations and Arbitration. You and the
                    Company agree that the following Disputes are not
                    subject to the above provisions concerning informal negotiations and
                    binding arbitration:
                    (i) any Disputes seeking to enforce or protect, or concerning the validity
                    of, any of your or the Company's intellectual property
                    rights;
                    (ii)
                    any Dispute related to, or arising from, allegations of theft, piracy,
                    invasion of privacy or unauthorized use; and
                    (iii)
                    any claim for injunctive relief.
                    10.7 Waiver/Severability.
                    (i)
                    The failure of the Company to require or enforce strict performance by you
                    of any provision of this T&C or to exercise
                    any right under this T&C shall not be construed as a waiver or
                    relinquishment of the Company's right to assert or rely upon any
                    such prov

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  createMarkup(data) {
    return { __html: data };
  }


}

export default TermsAndConditions;
