import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset } from "../../libs/functions";
import { toast } from "react-toastify";

class EditReels extends Component {
  constructor(props) {
    super(props);
    this.state = { post: {}, 
    title: "",
    description: "",
    imdb_url: "",
    post_type: 11, errors: [] };
    this.loadProject();
  }

  render() {
 
    return (
      <React.Fragment>
        <div className="container-fluid header">
        <div className="logo-wrapper">

<div className="logo"></div>
</div>
          <a
            href="/profile/0/workhistory"
            style={{ marginLeft: "-696px", color: "white" }}
          >
            &lt; Back
          </a>
        </div>
        <div className="row justify-content-center projects"></div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Reels</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="11"
            id="post_type_id"
            name="post[post_type]"
          />
          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <label>Reel Name</label>
              <input
                type="text"
                id="title"
                name="title"
                defaultValue={this.state.title || ""}
                onChange={(e) => {
                  this.setState({ title: e.target.value });
                }}
                placeholder="Reel Name"
                className="form-control"
              />
            </div>
           
            <div className="form-group">
              <label>Add Link</label>
              <input
                type="text"
                id="imdb_link"
                name="meta_data[imdb_link]"
                defaultValue={this.state.imdb_url}
                onChange={(e) => {
                  this.setState({ imdb_url: e.target.value });
                }}
                placeholder="Link"
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                id="body"
                name="body"
                defaultValue={this.state.description}
                onChange={(e) => {
                  this.setState({ description: e.target.value });
                }
                }
                placeholder="Description"
                className="form-control"
              />
            </div>


          </div>
          <div className="col-12 m-0 p-0">
            <button className="g-button" onClick={this.handleSubmit.bind(this)}>
              Save
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  isValidUrl(str) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      toast.error("Please enter valid URL.");
      return false;
    } else {
      return true;
    }
  }

  handleSubmit() {
    const id = this.props.match.params.id;
    var _errors = [];
    if (this.state.title.length < 1) {
      _errors.push("Title is required");
    }
    if (!this.isValidUrl(document.getElementById("imdb_link").value)) {
      _errors.push("Link is not a valid url");
    }
    if (_errors.length > 0) {
      this.setState({ errors: _errors });
      return;
    }



    var params = {
      title: this.state.title,
      description: this.state.description,
      imdb: this.state.imdb_url,
    }

    console.log("params", params);
    var api = new ApiRequest("post", "/user/add-reel");
    if (id) {
      api.url = "/user/edit-reel";
      api.method = "post";
      params.reel_id = id;
    }
    api.send(params, (result) => {
      window.location.href = "/profile/0/workhistory";
    });
  }

  loadProject() {
    const id = this.props.match.params.id;
    if (isset(id)) {
      let api = new ApiRequest("get", "/user/reel-detail?reel_id=" + id);
      api.send({}, (response) => {
        var _reel = response.data.reel;
      
        this.setState({
          title: _reel.title,
          description: _reel.description,
          imdb_url: _reel.imdb,
        });

      });
    }
  }

}

export default EditReels;
