import React, { Component } from "react";
import ApiRequest from "../libs/request.js";
import * as EmailValidator from 'email-validator';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.first_name = React.createRef();
    this.last_name = React.createRef();
    this.password = React.createRef();
    this.password_confirmation = React.createRef();
    this.errorsPanel = React.createRef();
    this.token = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid signup" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ width: '100%' }}>
            
            <div className="col-md-6 left-panel show-text-on-desktop  meta-text-paragraph" >
              <p className="auth-intro-text-signup" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


            <div className="col-md-6 right-panel  meta-text-paragraph" >

              <div className=" panel" style={{
                marginTop: "16vh",
                marginBottom: "40px",
              }}>
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/logo.png"
                    className=" homelogo signuplogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Create Art, Share Skills!</span>
                </div>
                <div className="login signuppanel">
                  <span className="h3">
                    Create New Account
                  </span>
                  <br />
                  <div className="errors" ref={this.errorsPanel}>
                    {" "}
                  </div>
                  <form>
                    <div className="field">
                      <input type="email" placeholder="Email" ref={this.email} />
                    </div>
                    <div className="field">
                      <input type="text" placeholder="First Name" ref={this.first_name} />
                    </div>
                    <div className="field">
                      <input type="text" placeholder="Last Name" ref={this.last_name} />
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        placeholder="Password"
                        ref={this.password}
                      />
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        placeholder="Password Confirmation"
                        ref={this.password_confirmation}
                      />
                    </div>
                    {/* <div className="field">
                  <input
                    type="text"
                    placeholder="Invitation Code (optional)"
                    ref={this.token}
                  />
                </div> */}
                    <div className="buttons">
                      <button
                        className="btn login-btnn"
                        onClick={this.handleSignupClick.bind(this)}
                        type="button"
                      >
                        Create Account
                      </button>
                      <span> Already have an account? <a href="/login">Login</a> </span>
                      <br />
                      <a
                        href="/terms-conditions"
                      >
                        Terms and Conditions
                      </a>
                      <span> | </span>
                      <a
                        href="/privacy"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6 left-panel show-text-on-mobile" >
              <p className="auth-intro-text-signup" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    if (this.state.errors) {
      this.errorsPanel.current.innerHTML = this.state.errors;
    }
  }

  handleSignupClick() {
    var validator = EmailValidator;
    if (this.email.current.value.length < 1) {
      this.setState({ errors: ["Please enter an email address"] });
      return;
    }

    if (!validator.validate(this.email.current.value)) {
      this.setState({ errors: ["Please enter a valid email address"] });
      return;
    }

    let params = {
      email: this.email.current.value,
      first_name: this.first_name.current.value,
      last_name: this.last_name.current.value,
      password: this.password.current.value,
      confirm_password: this.password_confirmation.current.value
    };
    // if (this.token.current.value.length > 0) {
    //   params["invite_code"] = this.token.current.value;
    // }

    let apiRequest = new ApiRequest("post", "/user/signup");
    apiRequest.send(params, (response) => {
      let result = response;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        localStorage.setItem("gorilla-token", result.token);

        location.href = "/";
      }
    });
  }
}

export default SignUp;
