import React, { Component } from "react";
import FeedList from "./FeedList";
import FeedStatusPanel from "./FeedStatusPanel";
import ApiRequest from "../../libs/request";
import MessagesPanel from "./MessagesPanel";
import Nav from "./Nav";
import { params } from "../../libs/functions";
import FeedNav from "./FeedNav";
import VoucherRequest from "../profile/VoucherRequest";
import SearchResults from "./SearchResults";
import LoadingSpinner from "../shared/loading-spinner/LoadingSpinner";
import SuggestFriends from "./SuggestFriends";

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeking: false,
      isLoading: false,
      loading: false,
      posts: [],
      current_page: 1,
      user: this.props.user,
      loadMore: true,
    };
    this.onCreatePost = this.onCreatePost.bind(this);
  }

  render() {
    var _search = params("search") ? params("search") : "";
    if (this.state.search_results && _search != "") {
      return this.renderSearchResults();
    }
    // if (this.props.user.friends_count >= 2 || this.props.user.is_verified) {
    return this.renderFeed();
    // } else {
    //   return this.renderVouchers();
    // }
  }

  renderSearchResults() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container ">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <SearchResults
                user={this.props.user}
                results={this.state.search_results}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderVouchers() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-12">
            <VoucherRequest user={this.props.user} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFeed() {
    var msg = localStorage.getItem("seeking_type");
    var title = msg ? msg : "" ? localStorage.getItem("seeking_type") : "Posts";
    var _panel = (
      <FeedStatusPanel
        user={this.state.user}
        onSubmitClick={(p, a, d, ans, company, tagged_users) =>
          this.handleSubmitPost( p, a, d, ans, company, tagged_users)
        }
      />
    );
    if (this.state.seeking || params("seeking")) {
      _panel = <h4>Available {title}</h4>;
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        {this.state.loading && <LoadingSpinner />}
        <div className="feed">
          <div className="row feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-6 col-lg-8 col-sm-12">
              <div className="feed-center">
              {_panel}{" "}
              <FeedList
                onLoadMore={this.onLoadMore}
                user={this.state.user}
                posts={this.state.posts}
              />
              {!this.state.isLoading && !params("id") && this.state.loadMore && (
                <div className="load-more" onClick={this.onLoadMore.bind(this)}>
                  Load more posts
                </div>
              )}
              {!this.state.isLoading && this.state.posts.length === 0 && (
                <div
                  id="nodata"
                  className="alert alert-success"
                  style={{ margin: "auto", width: "50%"}}
                >
                  {"No " + msg + " found"}
                </div>
              )}
              </div>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-12 ">
              <div className="feed-right">
                <SuggestFriends />
                <MessagesPanel user={this.state.user} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmitPost(
    pendingPost,
    assetIds,
    desc,
    answers,
    company_id,
    tagged_users
  ) {
    var api;
    if (pendingPost.id) {
      api = new ApiRequest("post", "/posts/" + pendingPost.id + "/publish");
      api.send(
        {
          asset_ids: assetIds,
          body: desc,
          answers: answers,
          company_id: company_id,
          meta_data: { taggedUser: tagged_users },
        },
        this.onCreatePost
      );
    } else {
      // api = new ApiRequest("post", "/posts");
      // let params = {
      //   post: {
      //     post_type: post_type,
      //     body: desc,
      //     answers: answers,
      //     company_id: company_id,
      //     meta_data: { taggedUser: tagged_users },
      //   },
      // };
      api = new ApiRequest("post", "/user/add-post");
      let params = {
          post: desc,
          company_id: company_id,
          tag_friend_ids: tagged_users,
      };
      api.send(params, this.onCreatePost);
    }
  }

  onCreatePost({ data }) {

    const posts = [data.data, ...this.state.posts];
    this.setState({ posts });

    this.forceUpdate();


  }

  componentDidMount() {
    // var api = new ApiRequest("get", "/user/view-profile");
    // api.send({}, (response) => {
    //   this.setState({ user: response.data.user });
    // if (params("search")) {
    //   this.fetchPosts();
    // } else {
      // if (this.props.user.friends_count >= 2) {
        this.fetchPosts();
      // } // });
    // }
  }

  fetchPosts() {
    this.setState({ isLoading: true, loading: true });

    if (params("seeking")) {
      this.questionSearch();
      return;
    }

    if (params("user_id")) {
      this.usersPost();
      return;
    }

    var _id = params("id");
    if (_id) {
      var api = new ApiRequest("get", "/posts.json?post_id=" + _id);
      api.send({}, (result) => {
        this.setState({ posts: result.data.data.results, isLoading: false, loading: false});
      });
    } else {
      var _type = params("type") ? params("type") : 1;
      var _search = params("search") ? params("search") : "";
      if (_search != "") {
        var api = new ApiRequest(
          "get",
          "/posts/global_search.json?search=" +
          _search +
          "&post_type=" +
          _type +
          "&page=" +
          this.state.current_page
        );
        api.send({}, ({ data }) => {
          this.setState({
            search_results: data.data.results,
            isLoading: false,
            loading: false,
            loadMore: data.data.results.length > 0,
          });
        });
      } else {
        var post_type = ""
        if (_type != "")
          post_type = "&post_type=" + _type;

        var postsApi = new ApiRequest(
          "get",
          // "/posts?page=" + this.state.current_page + post_type
          "/user/all-posts?page=" + this.state.current_page
        );
        postsApi.send({}, ({ data }) => {
          console.log('updatedPostData',data.posts.data);
          this.setState((prevState) => {
            return {
              posts: [...prevState.posts, ...data.posts.data],
              seeking: false,
              isLoading: false,
              loading: false,
              loadMore: data.posts.data.length > 0,
            };
          });
        });
      }
    }
  }

  onLoadMore() {
    this.setState(
      (prevState) => {
        return { current_page: prevState.current_page + 1 };
      },
      () => this.fetchPosts()
    );
  }

  questionSearch() {
    var _params = localStorage.getItem("seeking_search");
    if (!_params) {
      return;
    }
    var api = new ApiRequest("post", "/posts/question_search");
    api.send(JSON.parse(_params), ({ data }) => {
      this.setState({
        posts: data.data.results,
        seeking: true,
        isLoading: false,
        loading: false,
        loadMore: data.data.results.length > 0,
      });
    });
  }

  usersPost() {
    var api = new ApiRequest("get", "/user/user-posts?user_id=" + params("user_id"));
    api.send({}, ({ data }) => {
      this.setState({
        posts: data.posts.data,
        seeking: false,
        isLoading: false,
        loading: false,
        loadMore: data.posts.data.length > 0,
      });
    });
  }
}
export default Feed;
