import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Modal from "react-modal";
import Reviews from "./Reviews";
import { FaTimesCircle } from "react-icons/fa";
import FriendList from "./FriendList";
import ReportUser from "./ReportUser";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth:"500px",
    padding: "0px",
    borderRadius: "10px",
  },
};

class ProfileStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      more: "none",
    };

    this.handleReviews = this.handleReviews.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  render() {
    var rating = this.props.user.rating ? this.props.user.rating : "0.0";

    var review_request_btn = (
      <React.Fragment>
        <div className="ctrls" style={{ display: this.state.more }}>
          <button
            type="button"
            id="review-btn"
            onClick={this.handleReviewRequest.bind(this)}
          >
            Review Request
          </button>
          <button
            type="button"
            id="review-btn"
            onClick={this.handleReportUser.bind(this)}
          >
            Report User
          </button>
          <button
            type="button"
            id="review-btn"
            onClick={() =>
              (location.href = "/messages?user_id=" + this.props.user.id)
            }
          >
            Send Message
          </button>
        </div>
      </React.Fragment>
    );

    if (!this.props.showRequestButton) {
      review_request_btn = "";
    }
    var _comp = <Reviews user={this.props.user} />;
    if (this.state.modalComponent == "Friends") {
      _comp = (
        <FriendList
          showRequestButton={this.props.showRequestButton}
          user={this.props.user}
        />
      );
    }
    if (this.state.modalComponent == "Report User") {
      _comp = (
        <ReportUser
          onReportComplete={() => this.setState({ showModal: false })}
          user={this.props.user}
        />
      );
    }
    var _dots = (
      <span
        className="more-dots"
        onClick={() =>
          this.setState({
            more: this.state.more == "block" ? "none" : "block",
          })
        }
      >
        ...
      </span>
    );
    if (!this.props.showRequestButton) {
      _dots = "";
    }
    var stats = (
      <React.Fragment>
      
        <a
          href={"/?user_id=" + this.props.user.id}
          style={{ color: "#653ec1" }}
        >
          <span style={{ color: "#653ec1", fontWeight: "bold" }}>
            {this.props.user.post_count}
          </span>{" "}
          Posts
        </a>{" "}
        &nbsp;&nbsp;&nbsp;
        <a
          href="javascript:void(0)"
          style={{ color: "#653ec1" }}
          onClick={this.handleFriends.bind(this)}
        >
          <span style={{ color: "#653ec1", fontWeight: "bold" }}>
            {this.props.user.friend_count}
          </span>{" "}
          Friends
        </a>{" "}
       
        {_dots}
        <div className="profile-modals">
          <Modal
            isOpen={this.state.showModal}
            style={customModalStyles}
            contentLabel="Reviews"
            shouldCloseOnOverlayClick={true}
            overlayClassName="overlay"
          >
            <div className="modalHeader">
              {this.state.modalComponent}
              <div className="modalClose">
                <FaTimesCircle onClick={this.handleCloseModal.bind(this)} />
              </div>
            </div>
            <div className="modalContent">{_comp}</div>
          </Modal>
        </div>
      </React.Fragment>
    );

    return stats;
  }

  handleReviewRequest() {
    document.getElementById("review-btn").innerHTML = "Request Sent";
    let api = new ApiRequest("post", "/friends/request_review");
    api.send({ follower_id: this.props.user.id }, (result) => {});
  }

  handleReportUser() {
    this.setState({ modalComponent: "Report User", showModal: true });
  }

  handleReviews() {
    this.setState({ modalComponent: "Reviews", showModal: true });
  }
  handleFriends() {
    this.setState({ modalComponent: "Friends", showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
}
export default ProfileStats;
