import React, { Component } from "react";
import ApiRequest from "../libs/request.js";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.errorsPanel = React.createRef();
    this.state = { success: false, verifyCode: false };
  }

  render() {
    var success = [];
    if (this.state.success) {
      success.push(<div className="errors">Reset Instructions Sent</div>);
    }
    return (
      <React.Fragment>
           <div className="container-fluid signup" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ width: '100%' }}>

            <div className="col-md-6 left-panel show-text-on-desktop meta-text-paragraph" >
              <p className="auth-intro-text-reset" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


            <div className="col-md-6 right-panel  meta-text-paragraph" >

          <div className=" panel">
          <div style={{ marginBottom: "20px" }}>
             
               <img
                    src="/images/logo.png"
                    className=" homelogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
              <br />
              <span className="h5">Create Art, Share Skills!</span>
            </div>
            <div className="login">
              <span className="h3" style={{marginBottom: "0px"}}>Password Reset</span>

              <div className="errors" style={{
                fontSize: "18px",
                marginBottom: "12px"
              
              }} ref={this.errorsPanel}>
                {" "}
              </div>
              {success}
              <form>
                <div className="field">
                  <input type="email" placeholder="Email" ref={this.email} />
                </div>
                <div className="buttons">
                  <button
                    className="btn"
                    onClick={this.handleResetPassword.bind(this)}
                    type="button"
                  >
                    Send
                  </button>
                  not yet a member? <a href="/signup">sign up</a>
                </div>
              </form>
            </div>
          </div>
          </div>


            <div className="col-md-6 left-panel show-text-on-mobile" >
              <p className="auth-intro-text-reset" >
              Welcome to Today’s Painter, the platform for artists to connect, share, and monetize their expertise. Upload "how-to" videos, follow fellow artists, and interact with a vibrant community. With a vast library of instructional content and easy monetization options via PayPal or bank transfer, Today’s Painter empowers artists to learn and profit. Enjoy secure payments, privacy, and content moderation tools. Share your work on social media and join a growing community where creativity thrives.
              </p>
            </div>


          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    if (this.state.errors) {
      this.errorsPanel.current.innerHTML = this.state.errors;
    }
  }

  handleResetPassword() {
    var _errors = [];
    this.errorsPanel.current.innerHTML = "";
    if (this.email.current.value.length < 1) {
      return;
    }
    let params = { email: this.email.current.value };
    let apiRequest = new ApiRequest("post", "/user/forgot-password");
    apiRequest.send(params, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ success: false, errors: result.message });
      } else {
        this.setState({ success: true, errors: "" });
        window.location.href = "/update-password?email=" + this.email.current.value;
      }
    });
  }
}

export default ResetPassword;
