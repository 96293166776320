import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ApiRequest from '../../libs/request.js';
import Tagging from '../main/Tagging';
import { highLightTags } from '../../libs/functions.js';
import Popup from 'reactjs-popup';

function Comments(_props) {
    var _comments = [];

    const renderReplies = (replies, _edit) => {
        return replies.map((reply, idx) => (
            <>
                <div className="reply-item" key={idx}>
                    <img src={reply.user.profile_photo || '/images/placeholder.png'} alt="avatar"
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            float: "left",
                        }} />
                    <div className="reply-body" style={{ position: "relative" }}>
                        {reply.comment}
                        <div dangerouslySetInnerHTML={{ __html: highLightTags(reply) }} />
                        {/* Add edit and delete options for replies if needed */}
                        {_edit}
                    </div>
                </div>
            </>
        ));
    };

    var _edit = null;
    if (_props.comments.length > 0) {
        _props.comments.map((c, idx) => {
            var _obj = c;
            if (_obj.user_id === _props.user.id) {
                _edit = (
                    <React.Fragment>
                        <div className='comment-actions'>
                            <Popup
                                trigger={(open) => (<i class="fas fa-ellipsis-v"></i>
                                )}
                                position="bottom right"
                                arrow={false}
                                closeOnDocumentClick
                            >
                                <div className="block-user-text" onClick={() => _props.onDeleteClick(_obj.id)}
                                >Delete</div>
                            </Popup>
                        </div>

                        <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "4px", fontSize: "12px" }}>{
                                _obj.like_count.length > 0 ? _obj.like_count.length : ''
                            }</span>
                            <i class="far fa-heart comment-like-btn"
                                onClick={() => _props.onLikeClick(_obj.id)}
                            ></i>
                            <div
                                className="comment-edit"
                                onClick={() => _props.selectComment(_obj)}
                            >
                                reply
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else {
                _edit = (
                    <React.Fragment>
                        <div className='comment-actions'>
                            <Popup
                                trigger={(open) => (<i class="fas fa-ellipsis-v"></i>
                                )}
                                position="bottom right"
                                arrow={false}
                                closeOnDocumentClick
                            >
                                <div className="block-user-text" onClick={() => _props.onReportClick(_obj.id)}>Report</div>
                            </Popup>
                        </div>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "4px", fontSize: "12px" }}>{
                                _obj.like_count.length > 0 ? _obj.like_count.length : ''
                            }</span>
                            <i class="far fa-heart comment-like-btn"
                                onClick={() => _props.onLikeClick(_obj.id)}
                            ></i>
                            <div
                                className="comment-edit"
                                onClick={() => _props.selectComment(_obj)}
                            >
                                reply
                            </div>
                        </div>
                    </React.Fragment>
                );
            }

            var _avatar_image = _obj.user.profile_photo != null ? _obj.user.profile_photo : '/images/placeholder.png';

            _comments.push(
                <div className="new-comment-item" id={"comment" + _obj.id} key={idx}>
                    <div
                        className="comment-avatar"
                        style={{ backgroundImage: "url(" + _avatar_image + ")", borderRadius: "50%" }}
                    />
                    <div className="new-comment-body">
                        {_obj.comment}
                        <div dangerouslySetInnerHTML={{ __html: highLightTags(_obj) }} />{_edit}

                        {(_obj.replies && _obj.replies.length > 0) && (
                            <div className="replies">
                                {renderReplies(_obj.replies, _edit)}
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }



    return (
        <div>
            {_comments}
        </div>
    );
}



class CommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            comments: [],
            selectedComment: null,
        };
        this.handleCommentsDebounced = this.debounce(() => this.handleComments(), 1000); // Adjust the delay as needed
    }

    componentDidUpdate(prevProps) {
        const { post, isOpen } = this.props;
        if (isOpen && post && prevProps.isOpen !== isOpen) {
            this.fetchComments();
        }
    }

    render() {
        const { isOpen, onRequestClose } = this.props;
        const { comments, selectedComment, message } = this.state;
        const customModalStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth:'500px',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                overflowX: 'hidden',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        };

        return (
            <Modal
                style={customModalStyles}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Confirmation Modal"
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h6>Post Comments</h6>
                    <FaTimes onClick={onRequestClose} style={{ cursor: 'pointer' }} />
                </div>
                <div>
                    <Comments
                        post={this.props.post}
                        user={this.props.user}
                        friends={this.props.friends}
                        onhandleTaggedItem={this.props.onhandleTaggedItem}
                        comments={comments}
                        onDeleteClick={(comment) => this.handleDeleteComment(comment)}
                        onReportClick={(comment) => this.handleReportComment(comment)}
                        onLikeClick={(comment) => this.handleLikeComment(comment)}
                        selectComment={(comment) => this.selectComment(comment)}
                    />
                </div>
                < br />
                <div>
                    <div className="comment-form" style={{ display: "flex", position: "relative" }}>
                        <div
                            className="comment-avatar"
                            style={{ backgroundImage: "url(" + this.props.user.profile_photo + ")", marginRight: "10px", width: "46px", height: "36px" }}
                        />

                        <textarea
                            className="form-control"
                            style={{ width: '100%', border: '1px solid #99999961', height: '60px' }}
                            id="comment-text-input"
                            placeholder={selectedComment ? `Reply to ${selectedComment.user.full_name} here... ` : "Type your comment here..."}
                            value={message}
                            onChange={(e) => {
                                this.setState({ message: e.target.value });
                                // this.props.onAddComment(e);
                            }}
                        />

                        {selectedComment && <i className="fas fa-times"
                            title='Skip Reply'
                            style={{
                                position: "absolute",
                                right: "45px",
                                fontSize: "12px",
                                cursor: "pointer",
                                top: "5px",
                            }} onClick={() => this.setState({ selectedComment: null })}></i>}
                        <img
                            src="/images/send.png"
                            className='send-comment-icon'
                            onClick={this.handleCommentsDebounced}
                        />
                        {/* cross icon */}
                        <Tagging
                            onTagSelected={(friend) => this.props.onhandleTaggedItem(friend)}
                            friends={this.props.friends}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    fetchComments() {
        var api = new ApiRequest('get', '/user/post-comments?post_id=' + this.props.post.id);
        api.send({}, (response) => {
            const comments = response.data.comments;
            this.setState({ comments }); // Update comments state here
        });
    }

    selectComment(comment) {
        console.log(comment);
        this.setState({ selectedComment: comment });
    }

    handleComments() {
        if (this.state.message === '') {
            return;
        }
        let params = {
            post_id: this.props.post.id,
            comment: this.state.message,
            type: 0,
            main_comment: "",
        }

        if (this.state.selectedComment) {
            params.type = 1;
            params.main_comment = this.state.selectedComment.id;
        }

        var api = new ApiRequest(
            "post",
            "/user/add-comment"
        );
        api.send(params, (response) => {
            this.setState({ message: '' });
            this.fetchComments();
            this.forceUpdate();
        });
    }

    handleDeleteComment(id) {
        var api = new ApiRequest("post", "/user/delete-comment");
        api.send({
            comment_id: id,
        }, () => {
            this.fetchComments();
            this.forceUpdate();
        });
    }

    handleReportComment(id) {
        var api = new ApiRequest("post", "/user/report-comment");
        api.send({
            comment_id: id,
        }, () => {
            this.fetchComments();
            this.forceUpdate();
        });
    }

    handleLikeComment(id) {
        var api = new ApiRequest("post", "/user/like-comment");
        api.send({
            comment_id: id,
        }, () => {
            this.fetchComments();
            this.forceUpdate();
        });
    }

      // Debounce utility function
  debounce (func, delay) {
    let timeoutId;
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };
}

export default CommentModal;
