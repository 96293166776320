import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import MessagesPanel from "./MessagesPanel";
import auto from "autosize";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import MessageRecipients from "./MessageRecipients";
import { FaPaperclip } from "react-icons/fa";
import { validFile, params } from "../../libs/functions";
import EmojiPicker from "./EmojiPicker";
import ReactTimeAgo from "react-time-ago";
import Popup from "reactjs-popup";
import { MdBlock } from "react-icons/md";

function FilePreview(_props) {
  if (_props.files.length > 0) {
    var _prevs = [];
    var items = Array.from(_props.files);
    for (var i = 0; i < items.length; i++) {
      _prevs.push(
        <div
          className="img"
          style={{
            backgroundImage: "url('" + URL.createObjectURL(items[i]) + "')",
          }}
        >
          <i
            className="fas fa-trash"
            title="Remove"
            data-index={i}
            onClick={(e) => _props.onDelectClick(e)}
          />
        </div>
      );
    }
    return _prevs;
  }
  return <div />;
}

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroup: false,
      currentThread: null,
      files: [],
      messages: [],
      participants: [],
      current_page: 1,
      updatedAt: null,
      thread: 0,
      user: this.props.user,
      newRecips: [],
    };
    this.handleNewRecips = this.handleNewRecips.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
  }

  render() {
    if (this.state.user == null) {
      return <div />;
    }
    let _messages = [];
    let _bubbleCss = "";
    let _recipients = [];
    let _avatar_float = "left";
    let _recipSelect = [];
    if (this.state.messages.length > 0) {
      this.state.participants.map((r, idx) => {
        console.log("r", r);
        console.log("state.user.id", this.state.user);

        if (r.user.id.toString() != this.state.user.id.toString()) {
          _recipients.push(
            <div key={idx} className="message-header">
              <img src={r.user.profile_photo} />
              <div style={{
                display: 'grid'
              }}>
                <h2>{r.user.full_name}</h2>
                <p className="message-lastConversation">Last Conversation : {<ReactTimeAgo date={new Date(this.state.updatedAt)} locale="en-US" timeStyle="round" />}             </p>
              </div>

              <div className="message-block-options">
                <Popup
                  trigger={(open) => (<i class="fas fa-ellipsis-v"></i>
                  )}
                  position="bottom center"
                  closeOnDocumentClick
                >
                  <div style={{display: "flex"}}>
                  <MdBlock className="block-user" />
                    <div className="block-user-text">Block</div>
                  </div>
                </Popup>
              </div>

            </div>
          );
        }
      });
    } else {
      _recipSelect.push(
        <div key={0} className="feed-center">
          <MessageRecipients
            initialRecipients={params("user_id")}
            onSetRecipients={(recips) => this.handleNewRecips(recips)}
            user={this.state.user}
          />
        </div>
      );
    }
    try {
      this.state.messages.map((msg, i) => {
        _messages.push(
          <div key={i} className="main-message" style={{ display: "flow-root" }}>
            <div className="message-date" >{msg.date}</div>
            <div className="messages-group">
              {msg.messages.map((message, idx) => {
                _bubbleCss = "bubble";
                _avatar_float = "right";
                if (message.user.id.toString() !== this.state.user.id.toString()) {
                  _bubbleCss = "reply";
                  _avatar_float = "left";
                }
                var _attachment = "";
                if (message.attachment) {
                  _attachment = (
                    <a href={message.attachment} target="_blank">
                      <img src={message.attachment} width="200" />
                    </a>
                  );
                }
  
                return (
                  <React.Fragment key={"m" + idx}>
                    <div key={"bubble_" + idx} className={_bubbleCss}>
                      {_attachment}
                      {message.message}
                      {/* <span className="message-timestamp">
                      <ReactTimeAgo date={new Date(message.created_at)} locale="en-US" timeStyle="round-minute" />
                    </span> */}
                    </div>
                    {/* <div>
                    <ReactTimeAgo date={new Date(message.created_at)} locale="en-US" timeStyle="round" />
                  </div> */}
                    {/* <div
                    key={"avatar" + idx}
                    className={"sender-avatar " + _avatar_float}
                    style={{ backgroundImage: "url(" + message.user.profile_photo + ")" }}
                  /> */}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      });
    }
  
    catch (error) {
      console.error("Error processing messages:", error);
    }


    var _attachment_preview = "none";
    if (this.state.files.length > 0) {
      _attachment_preview = "block";
    }
    return (
      <React.Fragment>
        <Nav user={this.state.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-6 col-lg-7 col-sm-12 feed-center messages">
              {_recipients}
              <div className="message-list">
                {_recipSelect}

                <div className="inner-list">{_messages}</div>
                <input
                  type="file"
                  multiple={false}
                  name="files[]"
                  onChange={this.handleAttachementPreview.bind(this)}
                  id="attachment-files"
                  style={{ visibility: "hidden" }}
                />
                <div
                  className="attachment-prev"
                  style={{ display: _attachment_preview }}
                >
                  <FilePreview
                    onDelectClick={(e) => this.handleRemoveAttachment(e)}
                    files={this.state.files}
                  />
                </div>
               {<div className="message-field">
                  {/* <EmojiPicker field="message-text" /> */}
                  <textarea placeholder="Enter a message"
                    onKeyDown={(e) => {
                      if (e.shiftKey && e.keyCode === 13) {
                        e.preventDefault(); // Prevent the default behavior (e.g., new line)
                        // Optionally, you can handle adding a new line or other actions here
                        // For example, append a new line character to the text
                        const newText = e.target.value + "\n";
                        e.target.value = newText;
                      }

                      // if press enter key then send message
                      if (!e.shiftKey && e.keyCode === 13) {
                        e.preventDefault(); // Prevent the default behavior (e.g., new line)
                        this.handleMessageSend(e);
                      }
                    }}
                    id="message-text" style={{ borderRadius: "10px", width: "100%" }} />
                  {/* <a
                    href="#"
                    className="attachment message-attachement"
                    onClick={this.handleAttachement.bind(this)}
                  >
                    <FaPaperclip />
                  </a> */}

                  <img
                    src="/images/send.png"
                    onClick={this.handleMessageSend.bind(this)}
                  />
                </div>}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 feed-right">
              <MessagesPanel
                isGroup={this.state.isGroup}
                user={this.state.user}
                currentThread={this.state.currentThread}
              />
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </React.Fragment>
    );
  }

  handleRemoveAttachment(e) {
    var _index = e.target.dataset["index"];
    var items = [];
    var _files = Array.from(this.state.files);
    for (var i = 0; i < _files.length; i++) {
      if (i != _index) {
        items.push(_files[i]);
      }
    }
    this.setState({ files: items });
  }

  handleAttachementPreview() {
    var input = document.getElementById("attachment-files");
    if (input.files && input.files[0]) {
      if (validFile(input, "photo")) {
        this.setState({ files: input.files });
      }
    }
  }

  handleAttachement(e) {
    e.preventDefault();
    document.getElementById("attachment-files").click();
  }

  handleNewRecips(items) {
    this.setState({ newRecips: items });
  }

  handleMessageSend(e) {
    if (!e.keyCode || (e.keyCode == 13 && e.target.value.length > -1)) {
      let _body = document.querySelector("#message-text").value;
      console.log("this.state.newRecips", this.state.newRecips);
      var payload = new FormData();
      if (_body.length > 1) {
        payload.append("message", _body);
      }
      if (this.state.thread) {
        payload.append("chat_id", this.state.thread);
      }
      if (this.state.files.length > 0) {

        if (_body.length < 1) {
          payload.append("message", this.state.files[0].name);
        }

        var input = document.getElementById("attachment-files");
        payload.append("file", input.files[0]);
      }
      if (this.state.newRecips.length > 0) {
        this.handleNewMessageThread(payload);
        return;
      }
      var api = new ApiRequest(
        "post",
        "/user/send-message",
        false
      );
      api.send_file(payload, () => {
        document.querySelector("#message-text").value = "";
        document.querySelector("#message-text").style.height = "30px";
        this.setState({ files: [] });
        this.fetchThread();
      });
    }
  }

  handleNewMessageThread(payload) {
    var api = new ApiRequest("post", "/user/create-chat", false);
    var sendNewMessage = new ApiRequest("post", "/user/send-message", false);
    var _user_ids = [];
    console.log("_user_ids payload", payload);
    this.state.newRecips.map((u) => {
      payload.append("friend_ids[]", u.id);
    });

    api.send(payload, (response) => {
      sendNewMessage.send({ chat_id: response.data.chat_id, message: payload.get("body") || document.querySelector("#message-text").value }, (response2) => {
        console.log("response2", response2);
        document.querySelector("#message-text").value = "";
        document.querySelector("#message-text").style.height = "30px";
        this.setState({ files: [] });
        this.fetchThread();
        // if (response.message == "Message sent successfully")
        location.href =
          "/messages?thread=" + response.data.chat_id;
      });


      return;
    });
  }

  fetchThread() {
    let id = params("thread");
    try {
      if (id) {
        var api = new ApiRequest(
          "get",
          // "/messages/" + id + "?sort=messages.id asc",
          "/user/chat-messages?chat_id=" + id,
          false
        );
        try {
          api.send({}, (response) => {
            if (response.data.chat == undefined) {
              return;
            }
            var _data = response.data.chat.messages;
            // console.log("_data", _data.length > 0 ? _data[_data.length - 1] : null);
            // if _data.length > 0 then reverse the array
            if (_data.length > 0) {
              _data = _data.reverse();
              this.setState({
                updatedAt: _data[_data.length - 1].updated_at
              });
            }
            const messagesss = this.groupMessagesByDate(_data);
             // var _isGroup =
            //   _data.inbox &&
            //   _data.inbox.length > 0 &&
            //   _data.inbox[0].recipients.length > 2;
            var _isGroup = response.data.chat.participants.length > 2;
    
            this.setState({
              isGroup: _isGroup,
              currentThread: id,
              participants: response.data.chat.participants,
              messages: messagesss,
              thread: id,
            });
          });
        }
        catch (e) {
          console.log(e);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  groupMessagesByDate(messages) {
    const groupedMessages = [];

    messages.forEach((message) => {
      const messageDate = new Date(message.created_at).toLocaleDateString();

      // Check if the date group already exists
      const existingGroup = groupedMessages.find((group) => group.date === messageDate);

      if (existingGroup) {
        // Add message to existing group
        existingGroup.messages.push(message);
      } else {
        // Create a new date group
        groupedMessages.push({ date: messageDate, messages: [message] });
      }
    });

    return groupedMessages;
  };

  pollMessages() {
    setInterval(() => {
      this.fetchThread();
    }, 10000);
  }

  componentDidMount() {
    // var api = new ApiRequest("get", "/user/view-profile");
    // api.send({}, (response) => {
    //   this.setState({ user: response.data.user });
    auto(document.querySelectorAll("textarea"));
    this.fetchThread();
    this.pollMessages();
    // });
  }
}

export default Messages;
