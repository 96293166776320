import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Nav from "./Nav";
import FeedNav from "./FeedNav";

function UsersList(_props) {
  if (!_props.users) {
    return <div />;
  }
  let _table = [];

  _props.users.map((user, idx) => {
    console.log("user", user);
    var _display = user.status == "accepted" ? "none" : "block";
    var _btnCss =
      _props.pending.indexOf(user.friend.id.toString()) > -1
        ? "requested"
        : "";
    _table.push(
      <tr key={idx}>
        <td width="60">
          <a href={"/user/profile/" + user.friend.id + "#profile"}>
            <img
              src={user.friend.profile_photo === "https://api.gorillafilm.com/assets/images/gorilla_logo.jpeg"?"/images/short-logo.jpg":user.friend.profile_photo}
              height="40"
              width="40"
            />
          </a>
        </td>
        <td>
          <b>
            {user.friend.first_name} {user.friend.last_name}
          </b>
        </td>
        <td className="friend-req-btns">
         
          <button
            id={user.friend.id}
            className={"frnd-req-reject-btn" + _btnCss}
            style={{ display: _display }}
            type="button"
            onClick={() => _props.onButtonClick(user.friend)}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  });
  return (
    <table
      className="users-list"
      style={{ marginLeft: "12px", marginBottom: "24px" }}
    >
      <tbody>{_table}</tbody>
    </table>
  );
}

class ReviewRequests extends Component {
  constructor(props) {
    super(props);
    this.state = { friends: [], pending: [], review_user: null };
  }

  render() {
    if (this.state.review_user) {
      return this.reviewForm();
    } else {
      return this.list();
    }
  }

  reviewForm() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <h4>
                You Received A Review Request from{" "}
                {this.state.review_user.user.first_name}{" "}
                {this.state.review_user.user.last_name}{" "}
              </h4>
              <div className="row">
                <div className="col-3">
                  <img
                    src={this.state.review_user.user.profile_photo.thumb.url}
                    width="100"
                    height="100"
                  />
                </div>
                <div className="col-6">
                  <form>
                    <div className="form-group">
                      <label>Rating (optional)</label>
                      <input
                        type="range"
                        id="rating"
                        onChange={this.handleSlider.bind(this)}
                        className="form-control"
                        min="1"
                        max="10"
                        step="1"
                      />
                      <span className="sliderVal">1</span>
                    </div>
                    <div className="form-group">
                      <label>Review</label>
                      <textarea
                        id="comments"
                        className="form-control"
                      ></textarea>
                    </div>
                  </form>
                  <button
                    type="button"
                    className="g-button full"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmit() {
    var api = new ApiRequest("post", "/friends/review");
    api.send(
      {
        friend_id: this.state.review_user.id,
        from_user_id: this.props.user.id,
        to_user_id: this.state.review_user.user.id,
        rating: document.getElementById("rating").value,
        comments: document.getElementById("comments").value,
      },
      () => {
        this.setState({ review_user: null });
        this.loadData();
      }
    );
  }

  handleSlider(e) {
    let slider = document.querySelector(".sliderVal");
    slider.innerText = e.target.value;
  }

  list() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <h3 style={{ marginLeft: "12px", fontSize: "18px" }}>
                Review Sent Requests
              </h3>

              <UsersList
                users={this.state.friends}
                hasAction={"block"}
                pending={this.state.pending}
                onButtonClick={(user) => {
                  this.hanldeAccept(user);
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  hanldeAccept(user) {
    var api = new ApiRequest("post", "/user/cancel-request");
    api.send({ friend_id: user.id }, (result) => {
      
        this.loadData();
      
    });
  }

  loadData() {
    var api = new ApiRequest("get", "/user/view-requests?type=review");
    api.send({}, (result) => {
      console.log(result.data.requests)
      this.setState({ friends: result.data.requests });
    });
  }

  componentDidMount() {
    this.loadData();
  }
}
export default ReviewRequests;
